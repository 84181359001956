import store from 'store/index';

export default {
    getCubemapUrl1x6(id, floorID = null) {
        const { building } = store.getState();
        let result = null;
        if (!floorID) {

            building.floorList.forEach(floor => {
                try {
                    const { cubemapUrls1x6 } = floor;

                    if (cubemapUrls1x6[id]) {
                        result = cubemapUrls1x6[id];
                    }
                } catch (e) {
                    console.warn(e)
                        // continue;
                }
            })
        } else {
            building.floorList.forEach(floor => {
                if (floor.id == floorID) {
                    const { cubemapUrls1x6 } = floor;
                    result = cubemapUrls1x6[id]
                }
            })
        }

        return result;
    },
    getCubemapUrl(id, floorID = null) {
        const { building } = store.getState();
        let result = null;
        if (!floorID) {

            building.floorList.forEach(floor => {
                try {
                    const { cubemapUrls } = floor;

                    if (cubemapUrls[id]) {
                        result = cubemapUrls[id];
                    }
                } catch (e) {
                    console.warn(e)
                        // continue
                }
            })
        } else {
            building.floorList.forEach(floor => {
                if (floor.id == floorID) {
                    const { cubemapUrls } = floor;
                    result = cubemapUrls[id]
                }
            })
        }
        return result;
    },

    getAssignFloorData(floorID) {
        const { building } = store.getState();
        let floorData = null
        building.floorList.forEach(floor => {
            if (floor.id == floorID) {
                floorData = floor
            }
        })
        return floorData;
    },
    getCurrentRenderFloorData() {
        // let floorID = store.getState().currentRenderFloorID;
        let floorID = this.renderIndex2FloorId();

        if (floorID) {
            return this.getAssignFloorData(floorID);
        } else {
            return null;
        }
    },
    getCurrentRenderFloorStyles() {
        // let floorID = store.getState().currentRenderFloorID;
        let floorID = this.renderIndex2FloorId();

        if (floorID) {
            const data = this.getAssignFloorData(floorID);
            return data.styles
        } else {
            return null;
        }
    },
    getAllFloorData() {
        const { building } = store.getState();
        const allFloorData = {
            data: { floorplane: {}, group: [], panoramas: {}, status: 7 }, //TODO: merge status ?
        }

        building.floorList.forEach(floor => {
            try {
                const { data } = floor;

                // allFloorData.data.floorplane = {...allFloorData.data.floorplane, ...data.floorplane} //TODO: merge floorplane ?
                allFloorData.data.group = allFloorData.data.group.concat(data.group)
                allFloorData.data.panoramas = {...allFloorData.data.panoramas, ...data.panoramas }
            } catch (e) {
                console.warn(e)
                    // continue
            }
        })

        return allFloorData;
    },
    getCorespondHotspotIds(hotspot) {
        const { hotspotId, mainRoomId } = hotspot;
        // const { building, currentRenderFloorID } = store.getState();
        const { building } = store.getState();

        let roomIndex = -1
        search:
            building.floorList.forEach(floor => {
                try {
                    const { styles } = floor;
                    for (let i = 0; i < styles.length; i++) {
                        if (styles[i].panoIds.indexOf(mainRoomId) != -1) {
                            roomIndex = styles[i].panoIds.indexOf(mainRoomId);
                        }
                        // if (roomIndex != -1) {
                        //     break search;
                        // }
                    }
                } catch (e) {
                    console.warn(e)
                }
            })

        // get target style correspond hotspot
        const [_, hotspotFloorId] = this.getFloorId(hotspot)
        const { styles } = this.getAssignFloorData(hotspotFloorId);

        let hotspotIds = []
        for (let i = 0; i < styles.length; i++) {
            let id = styles[i].panoIds[roomIndex];
            if (id) {
                hotspotIds.push(id);
            }
        }

        return hotspotIds
    },
    getFloorId(hotspot) {
        // get which floor a hotspot is in
        const { hotspotId, mainRoomId } = hotspot;

        // const { building, currentRenderFloorID } = store.getState();
        // let result = [currentRenderFloorID, currentRenderFloorID]

        const { building } = store.getState();
        let currentRenderFloorID = this.renderIndex2FloorId();
        let result = [currentRenderFloorID, currentRenderFloorID];

        building.floorList.forEach(floor => {
            try {
                const { hierarchy, id } = floor;
                for (let i = 0; i < hierarchy.length; i++) {
                    if (hierarchy[i].panoramas[mainRoomId]) {
                        result = [currentRenderFloorID, id]
                    }
                }
            } catch (e) {
                console.warn(e)
                    // continue;
            }
        })

        return result;
    },
    renderIndex2FloorId() {
        const { currentRenderIndex, building } = store.getState();

        if (currentRenderIndex != -1 && typeof currentRenderIndex === 'number')
            return building.floorList[currentRenderIndex].id;

        return building.floorList[0].id;

    }
};