import React from 'react';
import { Trans } from 'react-i18next';
import {
  STATE,
  SynchronizeController,
} from 'services/3dviewer/moving-controls/synchronizeController';
import store from 'store';
import actions from 'store/actions';
import APIService from 'services/cloudApi.js';

import ruler from 'asset/image/icons-yc-ruler.svg';
import rulerUnusable from 'asset/image/icons-yc-ruler-unusable.svg';
import rulerSelect from 'asset/image/icons-yc-ruler-select.svg';
import VRIcon from 'asset/image/icons-yc-vrmode.svg';
import QRCode from 'asset/image/icons-yc-qrcode.svg';

export default class ToolBarMobile extends React.Component {
  constructor(props) {
    super(props);
    this.qrCodeUrl = '';
    this.state = {
      isFPview: false,
      isDrawRuler: false,
      isSupportVR: false,
      isVerify: false
    };
  }

  storeDataChange = () => {
    const { viewButton, viewVariable } = store.getState();
    if (viewButton) {
      const { viewState } = viewButton;
      this.setState({
        isFPview: viewState == STATE.FPVIEW,
        isDrawRuler: viewVariable.isDrawingRuler,
      });
    }
  };

  rulerSwitch() {
    const { viewButton, viewVariable } = store.getState();
    const { viewState } = viewButton;
    if (viewState == STATE.FPVIEW) {
      viewVariable.isDrawingRuler = !viewVariable.isDrawingRuler;
      store.dispatch(actions.setViewVariable(viewVariable));
    }
  }

  linkToYcPage() {
    window.open(this.qrCodeUrl, '_blank').focus();
  }

  switchVRMode() {
    const { viewButton } = store.getState();
    const { viewState } = viewButton;

    if (this.state.isSupportVR) {
      if (viewState == STATE.FPVIEW) {
        SynchronizeController.switchVRMode();
      }
    } else {
      alert("This device don't support webvr")
    }

    // SynchronizeController.switchVRMode();
  }

  render() {
    const { parent } = this.props;
    // const toolbarClass = this.state.isFPview ?  : 'tool-bar-topview';
    let rulerImage = rulerUnusable;
    if (this.state.isFPview) {
      if (this.state.isDrawRuler) {
        rulerImage = rulerSelect;
      } else {
        rulerImage = ruler;
      }
    }
    return (
      <>
        {
          this.state.isVerify == false && <div className={'tool-bar'}>
            <button onClick={() => this.rulerSwitch()}>
              <img src={rulerImage} alt="ruler" />
            </button>
            <button onClick={() => this.switchVRMode()} >
              <img src={VRIcon} alt="VRMode" />
            </button>
            <button
              className="mobile-hide-button"
              onClick={() => parent.triggerQRCodeBoard()}
            >
              <img src={QRCode} alt="QRCode" />
            </button>
          </div>
        }
      </>

    );
  }

  async componentDidMount() {
    const { id } = store.getState();
    this.unsubscribe = store.subscribe(this.storeDataChange); // add moniter changes from store and set listener
    this.storeDataChange();

    if ('xr' in navigator) {
      navigator.xr.isSessionSupported('immersive-vr').then(supported => {
        // console.log("VR support", supported)
        this.setState({
          isSupportVR: supported
        });
      });
    }

    this.qrCodeUrl = await APIService.fetchQRCodeUrl(id);

    APIService
      .fetchCaseStatus(id)
      .then(result => {
        if (
          result.detailStatus == 'pendingAcceptance' ||
          result.detailStatus == 'acceptanceFailed' ||
          result.detailStatus == 'acceptancePassed'
        ) {
          this.setState({
            isVerify: true
          })
        }
      })
      .catch(err => console.log(err));
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
}
