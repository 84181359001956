import React, { useState, useEffect, useRef } from 'react';
import firebaseApi from '../../../services/cloudApi';
import { useLocation } from 'react-router-dom';

function VerifySection() {
  const [isVerify, setIsVerify] = useState(true);
  const [status, setStatus] = useState('init'); // 'init', 'verifying', 'complete', 'typing reason', 'error'
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeNumber, setCodeNumber] = useState('');
  const inputRef = useRef();

  const location = useLocation();
  const buildingId = location.pathname.substring(1, location.pathname.length);

  useEffect(() => {
    firebaseApi
      .fetchCaseStatus(buildingId)
      .then(result => {
        if (
          result.detailStatus === 'pendingAcceptance' ||
          result.detailStatus === 'acceptanceFailed' ||
          result.detailStatus ==='acceptancePassed'
        ) {
          setIsVerify(false);
        } else {
          setIsVerify(true);
        }

        if (result.detailStatus === 'acceptanceFailed') {
          setStatus("failed")
        } else if (result.detailStatus === 'acceptancePassed') {
          setStatus("complete")
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const submit = async type => {
    if (loading) return
    setLoading(true)
    // 'pass', 'fail'
    const body = {
      objectId: buildingId,
      status: '',
      reason: '',
    };

    var d = new Date(),
      dformat =
        [d.getFullYear(), d.getMonth() + 1, d.getDate()].join('/') +
        ' ' +
        [d.getHours(), d.getMinutes()].join(':');
    console.log(dformat);

    if (type === 'pass') {
      body.status = 'acceptancePassed';
      body.reason = '驗收通過,' + dformat + ' 驗收人員編:' + codeNumber;
    } else if (type === 'fail') {
      body.status = 'acceptanceFailed';
      if (reason) {
        body.reason =
          '驗收不通過,' +
          dformat +
          ' 驗收人員編:' +
          codeNumber +
          ' 原因:' +
          reason;
      } else {
        return;
      }
    } else {
      return;
    }

    firebaseApi
      .setCaseStatus(body)
      .then(() => {
        if (type === 'pass') {
          setStatus('complete');
        } else {
          setStatus('failed');
        }
      })
      .catch(error => {
        setStatus('error');
        console.log('putVerifyData', error);
      })
      .finally(() => {
        setLoading(false)
      });
  };

  return (
    <div className={`verify-section ${isVerify ? 'done' : ''}`}>
      {status === 'init' && (
        <button
          onClick={() => setStatus('enterNumber')}
          className="trigger-btn"
        >
          驗收
        </button>
      )}
      {status === 'enterNumber' && (
        <div className="enterNumber-box">
          <div className="top-section">
            <h3>請輸入員編 ＋ 姓名 :</h3>
            <textarea
              id="id"
              ref={inputRef}
              value={codeNumber}
              onChange={e => setCodeNumber(e.target.value)}
              rows="12"
              cols="25"
            />
          </div>
          <div className="bottom-section">
            <button
              onClick={() => {
                if (codeNumber != '') {
                  setStatus('verifying');
                } else {
                  console.warn('請輸入員編');
                }
              }}
            >
              確定
            </button>
            <button onClick={() => {setStatus('init'); setCodeNumber('')}}>取消</button>
          </div>
        </div>
      )}
      {status === 'verifying' && (
        <div className="check-box">
          <div className="back-section">
            <button onClick={() => {setStatus('enterNumber'); setCodeNumber('');}}>←</button>
            <h3>驗收結果</h3>
          </div>
          <div className="back-section-index">
            <h3 className = "s1">請確認以下項目皆確認無誤,才可選擇驗收通過。<br /></h3>
            <h3 className = "s2">
            (1) 3D格局空間及外牆正確<br />
            (2) 3D格局空間命名正確<br />
            (3) 3D格局圖與原始格局圖一致<br />
            (4) 各空間可正常出入<br />
            </h3>
          </div>
          <div className="action-section pass">
            <button onClick={() => setStatus('confirm pass')}>✓ 通過</button>
          </div>
          <div className="action-section fail">
            <button onClick={() => setStatus('typing reason')}>✗ 不通過</button>
          </div>
        </div>
      )}
      {status === 'confirm pass' && (
        <div className="confirm-box">
          <div className="title">
            <label>驗收結果：通過</label>
          </div>
          <div className="content">
            <label>確認3D格局製作成果符合現況，可執行VR美裝設計?</label>
          </div>
          <div className="footer">
            <button onClick={() => submit('pass')}>確定</button>
            <button onClick={() => setStatus('verifying')}>取消</button>
          </div>
        </div>
      )}
      {status === 'typing reason' && (
        <div className="reason-box">
          <div className="title">
            <label>驗收結果：不通過</label>
          </div>
          <div className="content">
            <label>請說明3D格局與現況不符處或其他原因(必填):</label>
            <textarea
              id="reason"
              ref={inputRef}
              value={reason}
              onChange={e => setReason(e.target.value)}
              rows="12"
              cols="25"
            />
          </div>
          <div className="footer">
            <button onClick={() => submit('fail')}>確定</button>
            <button onClick={() => setStatus('verifying')}>取消</button>
          </div>
        </div>
      )}
      {status === 'complete' && (
        <button className="complete-text">通過</button>
      )}
      {
        status === 'failed' && (
          <button className="complete-text">不通過</button>
        )
      }
      {status === 'error' && (
        <button className="error-text">錯誤：請重整頁面</button>
      )}
    </div>
  );
}

export default VerifySection;
