import * as THREE from 'three';
import RenderOrder from './mat-manager';

function FloorPlaneBounding() {

    const wallBoundingGroup = new THREE.Group();
    wallBoundingGroup.renderOrder = RenderOrder.floorPlanRoomLine;
    const wallBoundingPosY = 0.2; // wall bounding init height
    const wallBoundingWidth = 0.05; // wall bounding init width
    const wallBoundingColor = new THREE.Color('white'); // room bounding color

    const doorBoundingGroup = new THREE.Group();
    doorBoundingGroup.renderOrder = RenderOrder.floorPlanDoor;
    const doorBoundingPosY = 0.2 + 1e-03; // door bounding init height
    const doorBoundingWidth = 0.02; // door bounding init width
    const doorBoundingColor = new THREE.Color(0x707070); // door bounding color

    const roomFloorAreaGroup = new THREE.Group();
    roomFloorAreaGroup.renderOrder = RenderOrder.floorPlanPlane;
    const roomFloorAreaInitColor = new THREE.Color('white'); // room floor area init color
    const roomFloorAreaInitPosY = 0.2; // room floor group init height
    const roomFloorAreaMaxAlpha = 0.3; // room floor group init alpha

    /**
     * 創建單一door bounding
     * @param {*} pos door bounding center position
     * @param {*} length bounding length
     */
    function createSingleDoorBounding(pos, length, isVirtual) {
        // 將長度設為length+singleWallWidth (解決轉角沒有連接問題)
        let width = isVirtual ? wallBoundingWidth : doorBoundingWidth
        const geometry = new THREE.BoxGeometry(length - wallBoundingWidth, 1e-02, width);
        const material = new THREE.MeshBasicMaterial({
            color: doorBoundingColor,
            transparent: true,
            opacity: 0,
            depthTest: true,
        });

        const singleBoundingDoor = new THREE.Mesh(geometry, material);
        singleBoundingDoor.name = 'DoorBounding';
        singleBoundingDoor.position.set(pos.x, 0, pos.y);

        return singleBoundingDoor;
    }
    /**
     * 創建單一wall bounding
     * @param {*} pos wall bounding center position
     * @param {*} length bounding length
     */
    function createSingleWallBounding(pos, length) {
        // 將長度設為length+singleWallWidth (解決轉角沒有連接問題)
        const geometry = new THREE.BoxGeometry(
            length + wallBoundingWidth,
            1e-03,
            wallBoundingWidth
        );
        const material = new THREE.MeshBasicMaterial({
            color: wallBoundingColor,
            transparent: true,
            opacity: 0,
            depthTest: true,
        });

        const singleBoundingWall = new THREE.Mesh(geometry, material);
        singleBoundingWall.name = 'WallBounding';
        singleBoundingWall.position.set(pos.x, 0, pos.y);

        return singleBoundingWall;
    }
    /**
     * 創建房間面積floorplan object
     * @param {*} allRoomModels
     */
    function createRoomFloorArea(roomsCarriers) {
        Object.values(roomsCarriers).forEach(room => {
            const { mesh } = room;
            const model = mesh;
            const geometry = new THREE.Geometry();
            geometry.merge(
                new THREE.Geometry().fromBufferGeometry(model.geometry),
                model.matrixWorld
            );

            const material = new THREE.MeshBasicMaterial({
                color: roomFloorAreaInitColor,
                side: THREE.FrontSide,
                transparent: true,
                opacity: 0,
                depthTest: true,
            });

            const singleRoomFloorArea = new THREE.Mesh(geometry, material);
            singleRoomFloorArea.scale.set(1, 0.01, 1);
            roomFloorAreaGroup.add(singleRoomFloorArea);
        });
        // set position y
        roomFloorAreaGroup.position.setY(roomFloorAreaInitPosY);
        return roomFloorAreaGroup;
    }
    /**
     * 創建wall bounding group
     * @param {*} roomsCarriers
     */
    function createWallBoundingGroup(roomsCarriers) {
        Object.values(roomsCarriers).forEach(room => {
            const { points, mesh } = room;
            for (let j = 0; j < points.length; j += 1) {
                const pointA = points[j];
                let pointB = null;

                // 兩兩point跑所有layout points
                if (j === points.length - 1) {
                    [pointB] = points;
                } else {
                    pointB = points[j + 1];
                }

                // 計算point距離來當bounding長度
                const length = pointA.distanceTo(pointB);
                let singleWall = null;

                // 計算pointA,B畫出來的線對應 positive x-axis angle 來定義是直線或橫線
                let angleDeg =
                    (Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x) * 180) / Math.PI;
                angleDeg = Math.abs(angleDeg);

                // 依據point x,y座標來定義 wall bounding位置
                if (angleDeg <= 45 || angleDeg >= 135) {
                    const midX = (pointA.x + pointB.x) / 2;
                    const centerPos = new THREE.Vector2(midX, pointA.y);
                    singleWall = createSingleWallBounding(centerPos, length);
                } else {
                    const midY = (pointA.y + pointB.y) / 2;
                    const centerPos = new THREE.Vector2(pointA.x, midY);
                    singleWall = createSingleWallBounding(centerPos, length);
                    singleWall.rotateY(Math.PI / 2);
                }

                // wall bounding 加到 room model children 繼承position scale rotation
                mesh.add(singleWall);
                singleWall.position.set(singleWall.position.x, 0, singleWall.position.z);

                // re-attach to group parent (change parent but keep position, scale, rotation)
                wallBoundingGroup.attach(singleWall);
                singleWall.position.setY(wallBoundingPosY);
            }
        });
        return wallBoundingGroup;
    }

    /**
     * 創建door bounding group
     * @param {*} doorsCarriers
     */
    function createDoorBoundingGroup(doorsCarriers) {
        Object.values(doorsCarriers).forEach(door => {
            const { mesh, info } = door;

            Object.keys(info).forEach(key => {
                const door = info[key];
                const { points, isVirtual } = door;
                const pointA = points[0];
                const pointB = points[1];

                // 計算point距離來當bounding長度
                const distance = pointA.distanceTo(pointB);
                let singleDoor = null;

                // 依據point x,y座標來定義 door bounding位置
                if (Math.abs(pointA.x - pointB.x) < 0.001) {
                    const midY = (pointA.y + pointB.y) / 2;
                    const centerPos = new THREE.Vector2(pointA.x, midY);
                    singleDoor = createSingleDoorBounding(centerPos, distance, isVirtual);
                    singleDoor.rotateY(Math.PI / 2);
                } else {
                    const midX = (pointA.x + pointB.x) / 2;
                    const centerPos = new THREE.Vector2(midX, pointA.y);
                    singleDoor = createSingleDoorBounding(centerPos, distance, isVirtual);
                }
                // door bounding 加到 door model children 繼承position scale rotation
                mesh.parent.add(singleDoor);
                singleDoor.position.set(singleDoor.position.x, 0, singleDoor.position.z);
                // re-attach to group parent (change parent but keep position, scale, rotation)
                doorBoundingGroup.attach(singleDoor);
                singleDoor.isVirtual = isVirtual;
                singleDoor.position.setY(doorBoundingPosY);
            })
        });
        return doorBoundingGroup;
    }

    function setDoorBoundingAlpha(alpha) {
        for (let i = 0; i < doorBoundingGroup.children.length; i += 1) {
            if (!doorBoundingGroup.children[i].isVirtual) {
                doorBoundingGroup.children[i].material.opacity = alpha;
            }
        }
    }

    function setWallBoundingAlpha(alpha) {
        for (let i = 0; i < wallBoundingGroup.children.length; i += 1) {
            wallBoundingGroup.children[i].material.opacity = alpha;
        }
    }

    function setAllRoomFloorAreaAlpha(alpha) {
        for (let i = 0; i < roomFloorAreaGroup.children.length; i += 1) {
            roomFloorAreaGroup.children[i].material.opacity =
                alpha * roomFloorAreaMaxAlpha;
        }
    }

    function setAllBoundingAlpha(alpha) {
        setWallBoundingAlpha(alpha);
        setDoorBoundingAlpha(alpha);
        setAllRoomFloorAreaAlpha(alpha);
    }

    function updatePosition(offset) {
        for (let i = 0; i < doorBoundingGroup.children.length; i += 1) {
            doorBoundingGroup.children[i].position.y = doorBoundingGroup.children[i].position.y + offset.y;
            doorBoundingGroup.children[i].updateMatrixWorld();
        }

        for (let i = 0; i < wallBoundingGroup.children.length; i += 1) {
            wallBoundingGroup.children[i].position.y = wallBoundingGroup.children[i].position.y + offset.y;
            wallBoundingGroup.children[i].updateMatrixWorld();
        }

        for (let i = 0; i < roomFloorAreaGroup.children.length; i += 1) {
            roomFloorAreaGroup.children[i].position.y = roomFloorAreaGroup.children[i].position.y + offset.y;
            roomFloorAreaGroup.children[i].updateMatrixWorld();
        }
    }

    function updateRenderOrder(offset) {
        wallBoundingGroup.renderOrder = RenderOrder.floorPlanRoomLine + offset;
        doorBoundingGroup.renderOrder = RenderOrder.floorPlanDoor + offset;
        roomFloorAreaGroup.renderOrder = RenderOrder.floorPlanPlane + offset;
    }

    return {
        createDoorBoundingGroup,
        createWallBoundingGroup,
        createRoomFloorArea,
        setAllBoundingAlpha,
        updatePosition,
        updateRenderOrder
    };
}

export default FloorPlaneBounding;