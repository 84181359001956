import React from 'react';
import store from 'store'
import StoreGetters from 'store/store-getters';
import FloorPlane2D from 'services/3dviewer/floorplan';
import './css/floorplan-canvas.scss';

export default class FloorPlaneCanvas extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.init();

        const { currentRenderIndex } = store.getState()
        this.currentRenderIndex = currentRenderIndex;
        this.unsubscribe = store.subscribe(this.onFloorchange);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            this.update();
        }
    }

    componentWillUnmount() {
        this.dispose();
        this.unsubscribe();
    }

    init = () => {
        const data = StoreGetters.getCurrentRenderFloorData().data.floorplane;
        const floorplane = new FloorPlane2D(this.canvas, data, this.props.viewerCameraController, this.props.objectManager);
        this.update = () => {
            floorplane.setCameraIcon();
            floorplane.updateViewPort();
        }
        this.update();
        this.dispose = floorplane.dispose;
    }

    onFloorchange = () => {
        // const { currentRenderFloorID , currentRenderIndex } = store.getState();
        const { currentRenderIndex } = store.getState();

        try {
            if (currentRenderIndex != this.currentRenderIndex) {
                this.dispose();
                this.init();

                this.currentRenderIndex = currentRenderIndex;
            }
        } catch (e) {
            console.log('onFloorchange failed !', e);
        }
    }

    render() {

        return ( <canvas 
            id = "FloorPlanContainer"
            className = "defaultFloorPlan"
            ref = {
                ref => {
                    this.canvas = ref;
                }
            }
            />
        );
    }
}