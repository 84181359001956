import * as THREE from 'three';
import { Vector3 } from 'three';
import * as Data from './format-parser';
// import { viewerCameraControls } from '../camera/cameraControls';
import * as iconSetting from './icons';

const {
    cameraMesh,
    createAreaMesh,
    createDoorMesh,
    createWallMesh,
} = iconSetting;

function calculateFloorplanaspect(group) {
    const bbox = new THREE.Box3().setFromObject(group);
    const size = new THREE.Vector3();
    bbox.getSize(size);
    return size.z / size.x;
}

export default function FloorPlane2D(canvas, rawdata, viewerCameraController, ObjectManager) {
    const viewerCameraControls = viewerCameraController;
    const objectControl = ObjectManager;
    const renderer = new THREE.WebGLRenderer({
        canvas,
        alpha: true,
        antialias: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x000000, 0);

    const camera = new THREE.OrthographicCamera(-1 / 2,
        1 / 2,
        1 / 2, -1 / 2,
        0.01,
        1000
    );
    camera.position.setY(100);
    camera.lookAt(0, 0, 0);

    const scene = new THREE.Scene();
    scene.add(cameraMesh);

    // init normalize Group
    const group = new THREE.Group();
    scene.add(group);
    group.rotateX(-Math.PI / 2);

    // parse data format
    const verticesDict = Data.extraVertices(rawdata);
    const areas = Data.extraArea(rawdata, verticesDict);
    const lines = Data.extraLines(rawdata, verticesDict);
    const holes = Data.extraholes(rawdata, verticesDict);

    // create areas
    const areaKeys = Object.keys(areas);
    areaKeys.forEach(key => {
        if (areas[key].length < 3) return;
        createAreaMesh(areas[key], group);
    });

    // create lines
    const lineKeys = Object.keys(lines);
    lineKeys.forEach(key => {
        const { start, end } = lines[key];
        createWallMesh(start, end, group);
    });

    // normalize objs in group
    let floorplan_setting = normalizeFloorPlane(group);
    // create door
    const holeKeys = Object.keys(holes);
    holeKeys.forEach(key => {
        createDoorMesh(holes[key], group);
    });

    const aspect = calculateFloorplanaspect(group);

    function normalizeFloorPlane(group) {
        const bbox = new THREE.Box3().setFromObject(group);
        const center = new THREE.Vector3();
        const size = new THREE.Vector3();
        const new_pos = new THREE.Vector3();
        bbox.getCenter(center);
        bbox.getSize(size);

        const maxScale = 1 / Math.max(size.x, size.y, size.z);
        new_pos.set(-center.x * maxScale, -center.y * maxScale, -center.z * maxScale)
        group.position.copy(new_pos);
        group.scale.set(maxScale, maxScale, maxScale);

        group.updateWorldMatrix(true, true);
        return {center: new_pos, size:1 / maxScale};
    }

    function updateViewPort() {
        renderer.setSize(canvas.clientHeight, canvas.clientWidth, false);
        const rendererAspect = canvas.clientHeight / canvas.clientWidth;

        let right = 1.4 / 2;
        let top = 1.4 / 2;

        if (aspect > 1) {
            // 平面圖y=1為base,對x軸還原比例
            if (right / rendererAspect > 1 / aspect) {
                right /= rendererAspect;
            }
            // 還原比例導致clip,改對y軸做還原
            else {
                top *= rendererAspect;
            }
        } else if (top * rendererAspect > aspect) {
            // 平面圖x=1為base,對y軸還原比例
            top *= rendererAspect;
        } else {
            // 還原比例導致clip,改對x軸做還原
            right /= rendererAspect;
        }

        camera.right = right;
        camera.left = -right;
        camera.top = top;
        camera.bottom = -top;

        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
    }

    function setCameraIcon(value) {
        cameraMesh.visible = value;
    }

    function dispose() {
        cancelAnimationFrame(requestID);

        while (scene.children.length > 0) {
            let obj = scene.children[0];

            if (obj.geometry) obj.geometry.dispose();

            scene.remove(obj);
        }

        group.traverse((obj) => {
            if (obj.geometry) obj.geometry.dispose();
        });

        renderer.dispose();
    }

    function render() {
        // set Camera ICON
        let new_pos = getCameraIconPos()
        cameraMesh.position.copy(new_pos);
        cameraMesh.rotation.y =
            viewerCameraControls.azimuthAngle > 0 ?
                viewerCameraControls.azimuthAngle :
                viewerCameraControls.azimuthAngle + 2 * Math.PI;

        renderer.render(scene, camera);
    }

    function getCameraIconPos() {
        const { setting } = objectControl.getAll()
        const maxScale = 1 / Math.max(setting.size.x, setting.size.y, setting.size.z);
        let new_camera_pos = viewerCameraControls.getCamerPosition().clone().multiplyScalar(maxScale / 0.25)
        return new_camera_pos
    }

    let requestID = -1

    function animate() {
        requestID = requestAnimationFrame(animate);
        render();
    }
    animate();
    window.addEventListener('resize', updateViewPort);

    return {
        updateViewPort,
        setCameraIcon,
        dispose
    };
}