import store from 'store';

function genObjectManagerWrapper(objManagers) {

    function resetAllModelTexture() {
        const { currentRenderIndex } = store.getState();
        if (currentRenderIndex != -1) {
            objManagers[currentRenderIndex].resetAllModelTexture();
            return;
        }

        for (let i = 0; i < objManagers.length; i++) {
            let objManager = objManagers[i];
            objManager.resetAllModelTexture();
        }
    }

    function resetAllFloorsModelTexture() {
        for (let i = 0; i < objManagers.length; i++) {
            let objManager = objManagers[i];
            objManager.resetAllModelTexture();
        }
    }

    function setAllMeshOpacity(roomOpacity, doorOpacity = 0) {
        const { currentRenderIndex } = store.getState();
        if (currentRenderIndex != -1) {
            objManagers[currentRenderIndex].setAllMeshOpacity(roomOpacity, doorOpacity);
            return;
        }

        for (let i = 0; i < objManagers.length; i++) {
            let objManager = objManagers[i];
            objManager.setAllMeshOpacity(roomOpacity, doorOpacity);
        }
    }

    function setAllFloorsMeshOpacity(roomOpacity, doorOpacity = 0) {
        for (let i = 0; i < objManagers.length; i++) {
            let objManager = objManagers[i];
            objManager.setAllMeshOpacity(roomOpacity, doorOpacity);
        }
    }

    // work around
    function setMeshOpacity(index, roomOpacity, doorOpacity = 0) {
        objManagers[index].setAllMeshOpacity(roomOpacity, doorOpacity);
    }

    function setAllHotspotOpacity(opacity) {
        const { currentRenderIndex } = store.getState();
        if (currentRenderIndex != -1) {
            objManagers[currentRenderIndex].setAllHotspotOpacity(opacity);
            return;
        }

        for (let i = 0; i < objManagers.length; i++) {
            let objManager = objManagers[i];
            objManager.setAllHotspotOpacity(opacity);
        }
    }

    function setAllFloorsHotspotOpacity(opacity) {
        for (let i = 0; i < objManagers.length; i++) {
            let objManager = objManagers[i];
            objManager.setAllHotspotOpacity(opacity);
        }
    }

    function setAllBoundingAlpha(alpha) {
        const { currentRenderIndex } = store.getState();
        if (currentRenderIndex != -1) {
            objManagers[currentRenderIndex].setAllBoundingAlpha(alpha);
            return;
        }

        for (let i = 0; i < objManagers.length; i++) {
            let objManager = objManagers[i];
            objManager.setAllBoundingAlpha(alpha);
        }
    }

    function setAllFloorsBoundingAlpha(alpha) {
        for (let i = 0; i < objManagers.length; i++) {
            let objManager = objManagers[i];
            objManager.setAllBoundingAlpha(alpha);
        }
    }

    return {
        resetAllModelTexture,
        resetAllFloorsModelTexture,
        setAllMeshOpacity,
        setAllFloorsMeshOpacity,
        setMeshOpacity,
        setAllHotspotOpacity,
        setAllFloorsHotspotOpacity,
        setAllBoundingAlpha,
        setAllFloorsBoundingAlpha
    };
}

function genSceneInfoWrapper(sceneInfos) {

    return {
        get sceneInfos() {
            return sceneInfos;
        },

        // scene objects
        get allRoomMesh() { // Group
            return sceneInfos.map(info => info.allRoomMesh);
        },

        get doorBoundingGroup() {
            return sceneInfos.map(info => info.doorBoundingGroup);
        },

        get wallBoundingGroup() {
            return sceneInfos.map(info => info.wallBoundingGroup);
        },

        get floorplanAreaGroup() {
            return sceneInfos.map(info => info.floorplanAreaGroup);
        },

        get rulerGroup() {
            return sceneInfos.map(info => info.rulerGroup);
        },

        /// single / multiple(default) floor ///
        get allHotspotsMeshArray() {
            let arrays = sceneInfos.map(info => info.allHotspotsMeshArray);
            return [].concat.apply([], arrays);
        },

        get currentHotspotMeshArray() {
            let arrays = sceneInfos.map(info => info.allHotspotsMeshArray);

            const { currentRenderIndex } = store.getState();
            if (currentRenderIndex != -1) {
                return arrays[currentRenderIndex];
            }

            return [].concat.apply([], arrays);
        },

        get allRoomsMeshArray() {
            let arrays = sceneInfos.map(info => info.allRoomsMeshArray);
            return [].concat.apply([], arrays);
        },

        get currentRoomMeshArray() {
            let arrays = sceneInfos.map(info => info.allRoomsMeshArray);

            const { currentRenderIndex } = store.getState();
            if (currentRenderIndex != -1) {
                return arrays[currentRenderIndex];
            }

            return [].concat.apply([], arrays);
        },

        get hotspots() {
            let arrays = sceneInfos.map(info => info.hotspots);
            let merge = {}
            for (let i = 0; i < arrays.length; i++) {
                for (let key in arrays[i]) {
                    merge[key] = arrays[i][key]
                }
            }
            return merge
                // return Object.assign.apply(Object, arrays);
        },

        get rooms() {
            let arrays = sceneInfos.map(info => info.rooms);
            let merge = {}
            for (let i = 0; i < arrays.length; i++) {
                for (let key in arrays[i]) {
                    merge[key] = arrays[i][key]
                }
            }
            return merge
                // return Object.assign.apply(Object, arrays);
        },

        get doors() {
            let arrays = sceneInfos.map(info => info.doors);
            let merge = {}
            for (let i = 0; i < arrays.length; i++) {
                for (let key in arrays[i]) {
                    merge[key] = arrays[i][key]
                }
            }
            return merge
                // return Object.assign.apply(Object, arrays);
        },
        ////////////////////////////////////////

        /// single floor ///////////////////////
        get roomGroup() {
            // const { floorList } = store.getState().building;
            // const { building, currentRenderFloorID } = store.getState();
            const { currentRenderIndex, building } = store.getState();
            const { floorList } = building
            // const currentFloorIndex = Object.keys(floorList).indexOf(currentRenderFloorID)
            if (currentRenderIndex != -1) {
                return sceneInfos[currentRenderIndex].roomGroup
            }

            return sceneInfos[0].roomGroup;
        },

        get mainroomHotspot() {
            // const { building, currentRenderFloorID } = store.getState();
            const { currentRenderIndex, building } = store.getState();
            const { floorList } = building
            // const currentFloorIndex = Object.keys(floorList).indexOf(currentRenderFloorID)
            if (currentRenderIndex != -1) {
                return sceneInfos[currentRenderIndex].mainroomHotspot
            }

            return sceneInfos[0].mainroomHotspot;
        },
        ////////////////////////////////////////

    }
}

export { genObjectManagerWrapper, genSceneInfoWrapper }