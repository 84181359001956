import * as THREE from 'three';
import store from 'store';

import {
  STATE,
  SynchronizeController,
} from 'services/3dviewer/moving-controls/synchronizeController';

function IconImage(
  iMainRoomId,
  iHotspotId,
  input_iconTexture,
  input_panelTexture
) {
  const manhattanRotation = 0;
  const texture = null;
  const cameraHeight = null;
  const rotateY = null;
  const hotspotId = iHotspotId;
  const mainRoomId = iMainRoomId;
  const geometry = new THREE.CircleGeometry(0.24, 32);
  const material = new THREE.MeshBasicMaterial({
    map: null,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 1.0,
    depthTest: false,
  });
  const mesh = new THREE.Mesh(geometry, material);
  const onClickColor = null;
  let mouseIsEnter = null;

  const iconTexture = input_iconTexture;
  const panelTexture = input_panelTexture;

  function update() {
    const { viewButton, viewVariable, currentHotSpot } = store.getState();
    // console.log("icon", this, hotspotId)
    if (viewButton) {
      const { viewState } = viewButton;

      if (viewState == STATE.FPVIEW) {
        setTexture(iconTexture);
        setOpacity(0.0);
        material.depthTest = false
        if ( hotspotId === currentHotSpot) {
          setOpacity(1.0);
        }
      } else if(viewState == STATE.FLOORPLAN){
        
        setTexture(panelTexture);
        setOpacity(1.0);
        material.depthTest = false
      }else{
        setTexture(panelTexture);
        setOpacity(1.0);
        material.depthTest = true
      }
    }
  }

  function destroy() {
    geometry.dispose();
    material.dispose();
    if (texture) texture.dispose();
  }
  function onMouseEnter() {
    // if (mouseIsEnter === false || mouseIsEnter == null) {
    //   mouseIsEnter = true;
    //   material.opacity = 1;
    // }
  }
  function onMouseLeave() {
    // if (mouseIsEnter === true) {
    //   mouseIsEnter = false;
    //   material.opacity = 0.4;
    // }
  }
  function onClick() {
    // material.color.set(onClickColor);
  }
  function normalizeScale(factor) {
    const newScale = mesh.scale.x * factor;
    mesh.scale.set(newScale, newScale, newScale);
  }
  function setManhattanRotation(iManhattanRotation) {
    this.manhattanRotation = iManhattanRotation;
  }
  function setTexture(iTexture) {
    material.map = iTexture;
    mesh.material = material;
  }
  function setCameraHeight(height) {
    this.cameraHeight = height;
  }
  function setRotateY(iRotateY) {
    this.rotateY = iRotateY;
  }
  function setOpacity(opacity) {
    material.opacity = opacity;
  }
  this.setTexture = setTexture;
  this.setRotateY = setRotateY;
  this.manhattanRotation = manhattanRotation;
  this.normalizeScale = normalizeScale;
  this.onClick = onClick;
  this.rotateY = rotateY;
  this.setCameraHeight = setCameraHeight;
  this.cameraHeight = cameraHeight;
  this.onMouseEnter = onMouseEnter;
  this.onMouseLeave = onMouseLeave;
  this.destroy = destroy;
  this.mainRoomId = mainRoomId;
  this.hotspotId = hotspotId;
  this.setOpacity = setOpacity;
  this.setManhattanRotation = setManhattanRotation;
  this.update = update;

  this.iconTexture = iconTexture;
  this.panelTexture = panelTexture;

  this.updateUnsubscribe = store.subscribe(this.update);
  Object.defineProperty(this, 'rotateY', {
    value: this.rotateY,
    enumerable: true,
  });
  Object.defineProperty(this, 'mesh', {
    value: mesh,
    enumerable: true,
  });
  Object.defineProperty(this, 'cameraHeight', {
    value: this.cameraHeight,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'mainRoomId', {
    value: this.mainRoomId,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'hotspotId', {
    value: this.hotspotId,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'objectType', {
    value: 'iconimage',
    enumerable: true,
  });
  Object.defineProperty(mesh, 'onMouseLeave', {
    value: this.onMouseLeave,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'onMouseEnter', {
    value: this.onMouseEnter,
    enumerable: true,
  });
}
export default IconImage;
