import React from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import actions from 'store/actions';
import APIService from 'services/cloudApi.js';
import Launcher from 'components/launcher';
import Viewer from 'components/viewer';
import { SynchronizeController } from 'services/3dviewer/moving-controls/synchronizeController';

import queryString from 'query-string';
import './main.scss';

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldViewerShow: false,
      shouldErrorShow: false,
      isDataReady: false,
      isViewerReady: false,
      isMultiFloor: false,
    };

    this.cache = null;
  }

  componentDidMount() {
    this.checkData();
    this.getQueryStringList();
  }

  async checkData() {
    const { buildingId } = this.props;

    //TODO: do we need this ?
    const data = await APIService.fetchMultifloorBuilding(buildingId);

    if (data) {
      let multifloorId = data.floors.data.map(data => data.ID);

      let showError = false;
      for (let i = 0; i < multifloorId.length; i++) {
        let floorId = multifloorId[i];
        const floorPanos = await APIService.fetchPanoramas(floorId);

        if (!floorPanos) {
          showError = true;
          break;
        }
      }

      if (!showError) {
        this.setState({ shouldViewerShow: true });
      } else {
        this.setState({ shouldErrorShow: true });
      }
    } else {
      const buildingPanos = await APIService.fetchPanoramas(buildingId);
      if (buildingPanos) {
        this.setState({ shouldViewerShow: true });
      } else {
        this.setState({ shouldErrorShow: true });
      }
    }
  }

  getQueryStringList = () => {
    // deal with query string
    const parsedStrs = queryString.parse(window.location.search);
    store.dispatch(actions.setQueryStringList(parsedStrs));
  };

  dataLoadingFinish = (isMultiFloor) => {
    this.setState({ isDataReady: true, isMultiFloor:isMultiFloor });
  };

  viewerLoadingFinish = () => {
    this.setState({ isViewerReady: true });
    this.cache = null;

    this.viewer.leftScene.canvas.addEventListener(
      'webglcontextlost',
      this.handleContextLost,
      false
    );
    this.viewer.leftScene.canvas.addEventListener(
      'webglcontextrestored',
      this.handleContextRestore,
      false
    );
  };

  handleContextLost = e => {
    this.setState(
      {
        isDataReady: false,
      },
      () => {
        // reset viewaer
        store.dispatch(actions.setViewButton(null));

        if (!this.cache) this.cache = SynchronizeController.cache();
        SynchronizeController.reset();

        // reopen viewer with new key
        this.setState({ isDataReady: true });
      }
    );
  };

  handleContextRestore = e => {
    console.log(e);
  };

  render() {
    const { buildingId } = this.props;
    const {
      shouldErrorShow,
      shouldViewerShow,
      isDataReady,
      isViewerReady,
    } = this.state;

    return (
      <>
        {shouldErrorShow && <div className="errorLog">Building Not Found.</div>}
        {shouldViewerShow && (!isDataReady || !isViewerReady) && (
          <Launcher buildingId={buildingId} onReady={this.dataLoadingFinish} />
        )}
        {shouldViewerShow && isDataReady && (
          <Viewer
            isMultiFloor={this.state.isMultiFloor}
            ref={ref => (this.viewer = ref)}
            changeFloor={this.changeFloor}
            onReady={this.viewerLoadingFinish}
            cache={this.cache}
          />
        )}
      </>
    );
  }
}

Main.propTypes = {
  buildingId: PropTypes.string.isRequired,
};
