import React, { Component } from 'react';
import classNames from 'classnames';

import store from 'store/index';
import StoreGetters from 'store/store-getters';

import styleIcon from 'asset/image/icons-vr-icon.svg';
import closeIcon from 'asset/image/icons-close.svg';
import splitIcon from 'asset/image/icons-split-screen.svg';
import splitSelectIcon from 'asset/image/icons-split-screen-select.svg';
import fullIcon from 'asset/image/icons-full-screen.svg';
import fullSelectIcon from 'asset/image/icons-full-screen-select.svg';
import { VIEW } from '../config';
import { STATE } from 'services/3dviewer/moving-controls/synchronizeController';

export default class StyleSelectionMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      styles: null,
      selectIndex: 1,
    };
  }

  componentDidMount() {
    store.subscribe(this.setStyleList);
  }

  changeStyle = mode => {
    this.props.parent.changeView(mode);
  };

  changeStyleIndex = index => {
    this.props.parent.changeSecondStyle(index);
    this.setState({
      selectIndex: index,
    });
  };

  setStyleList = () => {
    const floorStyle = StoreGetters.getCurrentRenderFloorStyles();
    const { viewButton } = store.getState();
    if (viewButton) {
      const { viewState } = viewButton;
      this.setState({
        styles: floorStyle,
        isFPview: viewState == STATE.FPVIEW,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.isFPview && (
          <>
            {this.props.mode == VIEW.SINGLE && (
              <div
                className="select-style-board-mobile"
                onClick={() => this.changeStyle(VIEW.DOUBLE)}
              >
                <img src={styleIcon} />
                <h3 className="select-style-button-name">VR美裝</h3>
              </div>
            )}
            {this.props.mode != VIEW.SINGLE && (
              <div className="select-style-board-open-mobile">
                <img
                  className={'select-style-board-icon-mobile'}
                  src={styleIcon}
                />
                {this.state.styles != null && (
                  <div className={'select-style-button-name-list'}>
                    {Object.keys(this.state.styles).map((b, index) => {
                      if (index != 0) {
                        var styleClass = classNames({
                          'select-style-button-name': true,
                          selected: this.state.selectIndex == index,
                        });
                        return (
                          <h3
                            key={index}
                            className={styleClass}
                            onClick={() => this.changeStyleIndex(index)}
                          >
                            {this.state.styles[b].name}
                          </h3>
                        );
                      }
                    })}
                  </div>
                )}
                <img
                  className={'select-style-board-close-mobile'}
                  src={closeIcon}
                  onClick={() => this.changeStyle(VIEW.SINGLE)}
                />
              </div>
            )}
            {this.props.mode != VIEW.SINGLE && (
              <div className="select-style-board-iconlist-mobile">
                <div
                  className="select-style-board-iconblock"
                  onClick={() => this.changeStyle(VIEW.RIGHTFULL)}
                >
                  <img
                    src={
                      this.props.mode == VIEW.RIGHTFULL
                        ? fullSelectIcon
                        : fullIcon
                    }
                  />
                </div>
                <div
                  className="select-style-board-iconblock"
                  onClick={() => this.changeStyle(VIEW.DOUBLE)}
                >
                  <img
                    src={
                      this.props.mode == VIEW.DOUBLE
                        ? splitSelectIcon
                        : splitIcon
                    }
                  />
                </div>
              </div>
            )}
            {this.props.mode != VIEW.SINGLE && (
              <div className={'select-style-signal-mobile'}>美裝後</div>
            )}
          </>
        )}
      </>
    );
  }
}
