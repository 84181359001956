const VIEW = {
    SINGLE: 'one view',
    DOUBLE: 'two view',
    RIGHTFULL: 'right full view'
};

const DROPDOWN = {
    CLOSE: 'close',
    OPEN: 'open',
}

const CanvasCSS = {
    // left: {
    //     width: '50%',
    //     height: '100%',
    //     position: 'absolute',
    //     bottom: '0',
    // },
    // right: {
    //     width: '50%',
    //     height: '100%',
    //     position: 'absolute',
    //     bottom: '0',
    //     right: '0',
    // },
    // whole: {
    //     width: '100%',
    //     height: '100%',
    //     position: 'absolute',
    //     bottom: '0',
    // },
    // none: {
    //     width: '0%',
    //     height: '100%',
    //     position: 'absolute',
    //     bottom: '0',
    // },
    // displayNone: {
    //     display: 'none',
    // }
    left: "mainScene-open",
    right: "secondScene-open",
    leftMobile: "mainScene-open-mobile",
    rightMobile: "secondScene-open-mobile",
    whole: "Scene-open",
    none: "Scene-close",
};

export { VIEW, DROPDOWN, CanvasCSS };