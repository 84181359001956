import zoomBonuce from '../moving-controls/zoomBounce';
import { SynchronizeController } from '../moving-controls/synchronizeController';
import store from 'store';

function CanvasEvents(
  mouseControls,
  movingController,
  mouseHintController,
  viewerCameraController,
  canvas,
) {

  function registTargets(
    allHotspotsMesh,
    allRoomMesh,
    allDoorMesh,
    allHotspots,
  ) {
    this.allHotspotsMesh = allHotspotsMesh;
    this.allRoomMesh = allRoomMesh;
    this.allDoorMesh = allDoorMesh;
    this.allHotspots = allHotspots;
    attach(canvas);
  }
  let binded = false;
  const scope = this;
  let selectedHotspot = null;
  const { raycaster } = mouseHintController;
  function onCanvasMouseMove(event) {
    mouseControls.update(event);
    const { currentHotSpot } = store.getState();

    // 走動中不顯示mouse hint point
    if (SynchronizeController.movingState === true) {
      mouseHintController.setMousePointVisible(false);
    } else {
      // 更新滑鼠在場景上標示位置
      mouseHintController.updateMousePoint(mouseControls.mouse, scope.allRoomMesh);
      if (movingController.nowIsTopView === false) {
        const roomIntersect = raycaster.intersectObjects(scope.allRoomMesh.concat(scope.allDoorMesh));
        const currentRoomId = roomIntersect[0].object.mainRoomId;
        // 若 raycast 射到的地方跟目前站點一致，則不顯示mouse hint point
        if (currentRoomId === currentHotSpot) {
          mouseHintController.setMousePointVisible(false);
        } else {
          mouseHintController.setMousePointVisible(true);
        }
      } else {
        mouseHintController.setMousePointVisible(true);
      }
    }

    // 設置raycast
    raycaster.setFromCamera(mouseControls.mouse, viewerCameraController.camera);
    const hotspotinteracts = raycaster.intersectObjects(scope.allHotspotsMesh);
    if (hotspotinteracts.length > 0) {
      if (hotspotinteracts[0].object.objectType === 'hotspot') {
        selectedHotspot = hotspotinteracts[0].object;
        if (!movingController.nowIsTopView) {
          // 鼠標指到hotspot 且hotspot不等於目前站點hotspot
          if (
            selectedHotspot.hotspotId !==
            movingController.destinationHotspot.hotspotId
          ) {
            selectedHotspot.onMouseEnter();
          }
        }
      }
    } else {
      // Raycast指到的非hotspot代表鼠標離開hotspot
      hotspotinteracts.length = 0;
      if (selectedHotspot != null) {
        // 鼠標離開hotspot -> 將上一次指到的hotspot改為原本顏色
        selectedHotspot.onMouseLeave();
        selectedHotspot = null;
      }
    }
  }
  function onCanvasMouseUp(event) {
    // 移動中不判定點擊事件
    if (mouseControls.isDragging(event)) return;
    if (SynchronizeController.movingState === true) return;

    raycaster.setFromCamera(mouseControls.mouse, viewerCameraController.camera);

    let objectInteracts;

    // 點擊目標為hotspot
    if (movingController.nowIsTopView === true) {
      // 目前是topView可點擊為 room + door mesh object
      objectInteracts = raycaster.intersectObjects(
        scope.allRoomMesh.concat(scope.allDoorMesh)
      );
      const { mainRoomId } = objectInteracts[0].object;
      SynchronizeController.top2HotSpot(
        scope.allHotspots[mainRoomId][mainRoomId]
      );
    } else {
      // raycast可以射到房間及hotspot -> 防止點擊到牆後hotspot
      objectInteracts = raycaster.intersectObjects(
        scope.allRoomMesh.concat(scope.allHotspotsMesh)
      );
      const { currentHotSpot } = store.getState();

      const currentRoomId = objectInteracts[0].object.mainRoomId;
      if (currentHotSpot === currentRoomId) {
        zoomBonuce(viewerCameraController);
      }
      if (
        objectInteracts.length > 0 &&
        objectInteracts[0].object.objectType !== 'hotspot'
      ) {
        // 點擊站點以外的地方移動
        // update mouse point
        mouseHintController.updateMousePoint(
          mouseControls.mouse,
          scope.allRoomMesh
        );
        // 移動至 raycast 射到的房間內站點
        const { mainRoomId } = objectInteracts[0].object;
        const hotspot = scope.allHotspots[mainRoomId][mainRoomId];
        SynchronizeController.hotspot2Hotspot(hotspot);
      } else {
        // 點擊明確站點的移動
        const { mainRoomId, hotspotId } = objectInteracts[0].object;
        const hotspot = scope.allHotspots[mainRoomId][hotspotId];
        SynchronizeController.hotspot2Hotspot(hotspot);
      }
    }
    if (selectedHotspot != null) {
      selectedHotspot.onMouseLeave();
      selectedHotspot = null;
    }
  }
  function attach(canvas) {
    if (binded === true) detach(canvas);
    canvas.addEventListener('mousedown', onCanvasMouseMove);
    canvas.addEventListener('touchstart', onCanvasMouseMove);
    canvas.addEventListener('mousemove', onCanvasMouseMove);
    canvas.addEventListener('touchmove', onCanvasMouseMove);
    canvas.addEventListener('mouseup', onCanvasMouseUp);
    canvas.addEventListener('touchend', onCanvasMouseUp);
    binded = true;
  }
  function detach(canvas) {
    canvas.removeEventListener('mousedown', onCanvasMouseMove);
    canvas.removeEventListener('touchstart', onCanvasMouseMove);
    canvas.removeEventListener('mousemove', onCanvasMouseMove);
    canvas.removeEventListener('touchmove', onCanvasMouseMove);
    canvas.removeEventListener('mouseup', onCanvasMouseUp);
    canvas.removeEventListener('touchend', onCanvasMouseUp);
    binded = false;
  }
  this.registTargets = registTargets;
  this.detach = detach;
  this.onCanvasMouseMove = onCanvasMouseMove;
  this.onCanvasMouseUp = onCanvasMouseUp;
}
export default CanvasEvents;
