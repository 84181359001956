import store from 'store';
import actions from 'store/actions';
import StoreGetters from 'store/store-getters';

function createViewBtn(viewBtnFuncs, to3D, toTop, toOrtho, siwperClick, state) {
  viewBtnFuncs[state]['goDown'].push(to3D)
  viewBtnFuncs[state]['goUp'].push(toTop)
  viewBtnFuncs[state]['toOrthographics'].push(toOrtho)
  viewBtnFuncs[state]['goDependOnState'].push(siwperClick)
  viewBtnFuncs[state]['viewState'].push(state)
}

function updateViewBtn(viewBtnFuncs, state) {
  store.dispatch(
    actions.setViewButton({
      'goDown': () => {
        viewBtnFuncs[state]['goDown'].forEach(func => {
          func();
        })
      },
      'goUp': () => {
        viewBtnFuncs[state]['goUp'].forEach(func => {
          func();
        })
      },
      'toOrthographics': () => {
        viewBtnFuncs[state]['toOrthographics'].forEach(func => {
          func();
        })
      },
      'goDependOnState': (e) => {
        viewBtnFuncs[state]['goDependOnState'].forEach(func => {
          func(e);
        })
      },
      'viewState': viewBtnFuncs[state]['viewState'][0],
    })
  );
}

export {
  createViewBtn,
  updateViewBtn
}