import DoorCarrier from './door';
import HotspotCarrier from './hotspot';
import RoomCarrier from './room';
import TextureTransitionCarrier from './TextureTransitionCarrier';
import IconImage from "./icon"

export default {
  DoorCarrier,
  HotspotCarrier,
  RoomCarrier,
  TextureTransitionCarrier,
  IconImage
};
