import store from 'store';
import actions from 'store/actions';
import StoreGetters from 'store/store-getters';
import { createViewBtn, updateViewBtn, getCorespondHotspot } from "./utils";
import { ObjectManagers } from '../object-manager';
import RenderOrder from '../object-manager/mat-manager';
import { genObjectManagerWrapper, genSceneInfoWrapper } from 'services/3dviewer/object-manager/wrapper'
import * as THREE from 'three';

const STATE = {
    FPVIEW: 'DOWN',
    TOPVIEW: 'TOP',
    FLOORPLAN: 'FLOORPLAN'
}

class TwoSceneSynchronizeController {
    constructor() {
        this.leftScene = null;
        this.rightScene = null;
        this.sceneInfos = []
        this.mapping = null;
        this.style = [0, 0]
        this.viewBtnFuncs = {}
        this.state = null;
        this.movingState = false;

        this.setState = (state, isMoving = true) => {
            switch (state) {
                case STATE.FPVIEW:
                    updateViewBtn(this.viewBtnFuncs, STATE.FPVIEW);
                    this.state = state;
                    this.isMoving = isMoving;
                    this.movingState = isMoving;
                    break;
                case STATE.TOPVIEW:
                    updateViewBtn(this.viewBtnFuncs, STATE.TOPVIEW);
                    this.state = state;
                    this.isMoving = isMoving;
                    this.movingState = isMoving;
                    break;
                case STATE.FLOORPLAN:
                    updateViewBtn(this.viewBtnFuncs, STATE.FLOORPLAN);
                    this.state = state;
                    this.isMoving = isMoving;
                    this.movingState = isMoving;
                    break;
                default:
                    updateViewBtn(this.viewBtnFuncs, this.state)
                    break;
            }

            if (isMoving) {
                // stop all pointer / touch events
                let div = document.getElementById('root');
                div.style.pointerEvents = 'none';

                document.addEventListener('contextmenu', this.stopPropagation);

                document.addEventListener('mousedown', this.stopPropagation);
                document.addEventListener('wheel', this.stopPropagation);

                document.addEventListener('touchstart', this.stopPropagation);
                document.addEventListener('touchend', this.stopPropagation);
                document.addEventListener('touchmove', this.stopPropagation);
            } else {
                // resume events
                let div = document.getElementById('root');
                div.style.pointerEvents = '';

                document.removeEventListener('contextmenu', this.stopPropagation);

                document.removeEventListener('mousedown', this.stopPropagation);
                document.removeEventListener('wheel', this.stopPropagation);

                document.removeEventListener('touchstart', this.stopPropagation);
                document.removeEventListener('touchend', this.stopPropagation);
                document.removeEventListener('touchmove', this.stopPropagation);
            }

            this.handleMesh(this.state, this.leftScene);
            this.handleMesh(this.state, this.rightScene);

            const { currentRenderIndex } = store.getState();
            this.handleRenderOrder(currentRenderIndex, this.leftScene);
            this.handleRenderOrder(currentRenderIndex, this.rightScene);
        }
    }
    get isMoving() {
        const { viewVariable } = store.getState();
        return viewVariable.isCameraMoving;
    }
    set isMoving(status) {
        const { viewVariable } = store.getState();
        viewVariable.isCameraMoving = status
        store.dispatch(actions.setViewVariable(viewVariable))
    }

    stopPropagation = (event) => {
        event.stopPropagation();
    }

    handleMesh = (state, scene) => {
        const { mesh } = scene.movingController.fpviewMesh;
        switch (state) {
            case STATE.FPVIEW:
                if (mesh.parent === null) scene.scene.add(mesh);
                break;
            case STATE.TOPVIEW:
                if (mesh.parent !== null) scene.scene.remove(mesh);
                break;
            case STATE.FLOORPLAN:
                if (mesh.parent !== null) scene.scene.remove(mesh);
                break;
            default:
                break;
        }
    }

    handleRenderOrder = (index, scene) => {
        scene.currentSceneInfo.sceneInfos.map((info, sceneindex) => {
            for (var id in info.rooms) {
                let mesh = info.rooms[id].mesh
                mesh.renderOrder = (sceneindex == index) ? RenderOrder.room - 3 : RenderOrder.room;
            }

            for (var id in info.hotspots) {
                let mesh = info.hotspots[id][id].mesh
                mesh.renderOrder = (sceneindex == index) ? RenderOrder.hotspot - 3 : RenderOrder.hotspot;
            }

            for (var id in info.doors) {
                let mesh = info.doors[id].mesh
                    // mesh.renderOrder = (sceneindex == index) ? RenderOrder.door - 3 : RenderOrder.door;
            }

            if (sceneindex == index) {
                info.floorPlaneBounding.updateRenderOrder(-40);
            } else {
                info.floorPlaneBounding.updateRenderOrder(0);
            }
        });
    }

    handleOpacity = (scene, hotspot = 0, room = 0, door = 0, bounding = 0) => {
        scene.ObjectManager.setAllHotspotOpacity(hotspot);
        scene.ObjectManager.setAllMeshOpacity(room, door);
        scene.ObjectManager.setAllBoundingAlpha(bounding);
    }

    reset() {
        this.leftScene = null;
        this.rightScene = null;
        this.sceneInfos = []
        this.mapping = null;
        this.style = [0, 0]
        this.viewBtnFuncs = {}
        this.state = null;
        this.movingState = false;
    }

    updateFloorPosition() {
        // offset all rooms in multifloor building
        let total_floors = Object.values(this.mapping).length;
        Object.values(this.mapping).map((floor, floorIndex) => {

            Object.values(floor).map(data => {

                let info = this.sceneInfos[data]

                // TODO : remove according cloud data
                for (var index in info.allRoomsMeshArray) {
                    let mesh = info.allRoomsMeshArray[index]
                    mesh.position.y = mesh.position.y + 1.2 * floorIndex;
                    mesh.updateMatrixWorld();
                }

                for (var id in info.rooms) {
                    let tagGroup = info.rooms[id].tagGroup
                    tagGroup.position.y = tagGroup.position.y + 1.2 * floorIndex;
                    tagGroup.updateMatrixWorld();
                }

                info.floorPlaneBounding.updatePosition(new THREE.Vector3(0, 0.2 * (total_floors - 1), 0));

                info.rulerGroup.position.y = info.rulerGroup.position.y + 0.3 * floorIndex;
                info.rulerGroup.updateMatrixWorld();
            })
        })
    }

    async init(isMultiFloor, onReady, cache = null) {
        if (isMultiFloor) store.dispatch(actions.setCurrentRenderIndex(-1));
        else store.dispatch(actions.setCurrentRenderIndex(0));
        /// temp merge all scene infos ///

        this.updateFloorPosition();
        let mergeInfos = []
        mergeInfos.push(genSceneInfoWrapper(this.createTotalSceneInfo()))
        mergeInfos.push(genSceneInfoWrapper(this.createTotalSceneInfo(-1, 1)))

        let mergeObjManagers = []
        mergeObjManagers.push(genObjectManagerWrapper(this.createTotalObjectManager()))
        mergeObjManagers.push(genObjectManagerWrapper(this.createTotalObjectManager(-1, 1)))
            //////////////////////////////////
        await this.rightScene.init(mergeObjManagers[1], mergeInfos[1]);
        await this.leftScene.init(mergeObjManagers[0], mergeInfos[0]);
        this.sync();

        /// caching works only in FPVIEW !!! ///
        if (cache) {
            this.setState(STATE.FPVIEW);
            const [waitLeftRotate, waitLeftMove] = this.rightScene.start(cache);
            const [waitRightRotate, waitRightMove] = this.leftScene.start(cache);

            Promise.all([waitLeftRotate, waitRightRotate]).then(() => {
                this.setState(STATE.FPVIEW, false);
            });

            onReady();
            return;
        }
        /////////////

        const { queryStringList } = store.getState();
        if (queryStringList.mode == 'FPVIEW') {
            this.setState(STATE.TOPVIEW, false);

            let floor = queryStringList.floor || 0;
            this.changeFloor(floor);

            let leftConfig = {
                mode: STATE.FPVIEW,
                hotspot: this.leftScene.currentSceneInfo.mainroomHotspot,
                position: null,
                rotation: null
            }

            let rightConfig = {
                mode: STATE.FPVIEW,
                hotspot: this.rightScene.currentSceneInfo.mainroomHotspot,
                position: null,
                rotation: null
            }

            this.setState(STATE.FPVIEW);
            const [waitLeftRotate, waitLeftMove] = this.rightScene.start(rightConfig);
            const [waitRightRotate, waitRightMove] = this.leftScene.start(leftConfig);

            Promise.all([waitLeftRotate, waitRightRotate]).then(() => {
                this.setState(STATE.FPVIEW, false);
                onReady();
            });
        } else if (queryStringList.mode == 'FLOORPLANE') {
            this.setState(STATE.TOPVIEW, false);

            let floor = queryStringList.floor || 0;
            this.changeFloor(floor);

            let config = {
                mode: STATE.FLOORPLAN,
                hotspot: null,
                position: null,
                rotation: null
            }

            this.setState(STATE.FLOORPLAN);
            const [waitLeftRotate, waitLeftMove] = this.rightScene.start(config);
            const [waitRightRotate, waitRightMove] = this.leftScene.start(config);

            Promise.all([waitLeftRotate, waitRightRotate]).then(() => {
                this.setState(STATE.FLOORPLAN, false);
                onReady();
            });
        } else {
            this.setState(STATE.TOPVIEW);

            const [waitLeftRotate, waitLeftMove] = this.rightScene.start();
            const [waitRightRotate, waitRightMove] = this.leftScene.start();

            Promise.all([waitLeftRotate, waitRightRotate]).then(() => {
                this.setState(STATE.TOPVIEW, false);

                if (this.leftScene.movingController.firstMove2MainRoom) {
                    this.setState(STATE.FPVIEW);

                    Promise.all([waitLeftMove, waitRightMove]).then(() => {
                        this.setState(STATE.FPVIEW, false);
                    });
                }

                if(queryStringList.anim == "true"){
                    const { building } = store.getState();
                    const sortMainroomHotspotID = building.floorList[0].panoramaSort[0][0].objectId;
                    this.top2HotSpot(ObjectManagers[0].getAll().hotspots[sortMainroomHotspotID][sortMainroomHotspotID]);
                    //console.log(this.leftScene.currentSceneInfo.mainroomHotspot)
                    //this.top2HotSpot(this.leftScene.currentSceneInfo.mainroomHotspot)
                }
            });

            

            onReady();
        }
    }

    cache = () => {
        return {
            hotspot: this.leftScene.movingController.destinationHotspot,
            position: this.leftScene.viewerCameraController.camera.position,
            rotation: this.leftScene.viewerCameraController.camera.rotation
        }
    }

    initViewBtnFuncs(STATE) {
        let funcs = {}
        for (let key in STATE) {
            funcs[STATE[key]] = {
                'goDown': [],
                'goUp': [],
                'toOrthographics': [],
                'goDependOnState': [],
                'viewState': [],
            }
        }
        return funcs
    }

    setRenderScenes(leftScene, rightScene) {
        this.leftScene = leftScene;
        this.rightScene = rightScene;
    }

    setSceneInfo(info, mapping) {
        this.sceneInfos.push(info);
        this.mapping = mapping;
    }

    createTotalSceneInfo(render = -1, style = 0) {
        if (render == -1)
            return Object.values(this.mapping).map(map => this.sceneInfos[map[style] || 0]);
        else {
            return [this.sceneInfos[Object.values(this.mapping)[render][style]]]
        }
    }

    createTotalObjectManager(render = -1, style = 0) {
        if (render == -1)
            return Object.values(this.mapping).map(map => ObjectManagers[map[style] || 0]);
        else {
            return [ObjectManagers[Object.values(this.mapping)[render][style]]]
        }
    }

    switchVRMode() {
        this.leftScene.viewerCameraController.switchVRMode()
    }

    changeFloor(index, destinationHotspot = [null, null], lastDownViewHotspot = [null, null]) {
        return new Promise((resolve) => {
            const { viewButton, currentRenderIndex } = store.getState();

            if (viewButton == null || currentRenderIndex == index) resolve();

            // set all opacity to 0, 0.1 for all room
            if (viewButton.viewState != STATE.FPVIEW) {
                this.handleOpacity(this.rightScene, 0, 0.1, 0, 0);
                this.handleOpacity(this.leftScene, 0, 0.1, 0, 0);
            }

            let last_index = currentRenderIndex;
            store.dispatch(actions.setCurrentRenderIndex(index));

            let waitTransition = []
            switch (viewButton.viewState) {
                case STATE.FPVIEW:
                    let waitAllRoomsTransitionMesh = []
                    waitAllRoomsTransitionMesh.push(this.rightScene.handleChangeFloor())
                    waitAllRoomsTransitionMesh.push(this.leftScene.handleChangeFloor())

                    waitTransition.push(Promise.all(waitAllRoomsTransitionMesh).then(() => {
                        const { mainroomHotspot } = this.leftScene.currentSceneInfo;
                        // return this.hotspot2Hotspot(mainroomHotspot)
                        return this.hotspot2AnyHotspot(mainroomHotspot)
                    }))

                    // set current hotspot opacity
                    this.handleOpacity(this.rightScene, 0.4, 0, 0, 0);
                    this.handleOpacity(this.leftScene, 0.4, 0, 0, 0);
                    break;
                case STATE.TOPVIEW:
                    // set current room opacity
                    this.handleOpacity(this.rightScene, 0, 1, 0, 0);
                    this.handleOpacity(this.leftScene, 0, 1, 0, 0);
                    break;
                case STATE.FLOORPLAN:
                    // set current room / bounding opacity
                    this.handleOpacity(this.rightScene, 0, 1, 0, 1);
                    this.handleOpacity(this.leftScene, 0, 1, 0, 1);
                    break;
                default:
                    break;
            }

            this.handleRenderOrder(index, this.rightScene);
            this.handleRenderOrder(index, this.leftScene);

            Promise.all(waitTransition).then(() => {
                if (viewButton.viewState == STATE.FPVIEW) {
                    this.rightScene.ObjectManager.setMeshOpacity(last_index, 0.1);
                    this.leftScene.ObjectManager.setMeshOpacity(last_index, 0.1);
                }

                let wait = []
                wait.push(this.rightScene.resolveChangeFloor())
                wait.push(this.leftScene.resolveChangeFloor())

                Promise.all(wait).then(() => {
                    resolve();
                });
            })
        })
    }

    changeStyle(index) {
        this.setState(null);
        return new Promise((resolve) => {
                let mergeInfos = []
                mergeInfos.push(genSceneInfoWrapper(this.createTotalSceneInfo(-1, index)))

                let mergeObjManagers = []
                mergeObjManagers.push(genObjectManagerWrapper(this.createTotalObjectManager(-1, index)))

                //換成新building的站點
                let destinationHotspot = null;
                if (this.rightScene.movingController.destinationHotspot != null) {
                    let query = StoreGetters.getCorespondHotspotIds(this.rightScene.movingController.destinationHotspot);
                    destinationHotspot = this.getCorespondHotspot(query, mergeInfos[0]);
                }

                let lastDownViewHotspot = null;
                if (this.rightScene.movingController.lastDownViewHotspot != null) {
                    let query = StoreGetters.getCorespondHotspotIds(this.rightScene.movingController.lastDownViewHotspot);
                    lastDownViewHotspot = this.getCorespondHotspot(query, mergeInfos[0]);
                }

                const wait = this.rightScene.changeStyle(mergeObjManagers[0], mergeInfos[0], destinationHotspot, lastDownViewHotspot);

                // console.log(mergeInfos[0])

                this.sync();
                wait.then(() => {
                    // this.style[1] = 1;
                    this.setState(null, false);
                    resolve();
                });
            })
            // return wait.then(() => this.setState());
    }

    sync() {
        const hotspot2AnyHotspotSyncIDFunc = (id) => {
            if (id === '') return;
            if (this.leftScene.currentSceneInfo.hotspots[id] !== undefined)
                this.hotspot2AnyHotspot(this.leftScene.currentSceneInfo.hotspots[id][id])
            else
                this.hotspot2AnyHotspot(this.rightScene.currentSceneInfo.hotspots[id][id])
        }
        const top2HotspotSyncIDFunc = (id) => {
            if (id === '') return;
            if (this.leftScene.currentSceneInfo.hotspots[id] !== undefined)
                this.top2HotSpot(this.leftScene.currentSceneInfo.hotspots[id][id])
            else
                this.top2HotSpot(this.rightScene.currentSceneInfo.hotspots[id][id])
        }

        this.viewBtnFuncs = this.initViewBtnFuncs(STATE)
        this.creatBtnFuncs(top2HotspotSyncIDFunc, hotspot2AnyHotspotSyncIDFunc)
    }

    creatBtnFuncs(top2HotSpotBtn, hotspot2AnyHotspotBtn) {
        createViewBtn(
            this.viewBtnFuncs,
            this.GoToLastDownViewPos,
            this.transition2Perspective,
            () => {},
            top2HotSpotBtn,
            STATE.FLOORPLAN
        );
        createViewBtn(
            this.viewBtnFuncs,
            this.GoToLastDownViewPos,
            () => {},
            this.GoOrthographicsView,
            top2HotSpotBtn,
            STATE.TOPVIEW
        );
        createViewBtn(
            this.viewBtnFuncs,
            () => {},
            this.BackToStartPos,
            this.GoOrthographicsView,
            hotspot2AnyHotspotBtn,
            STATE.FPVIEW
        );
    }

    getCorespondHotspot = (query, sceneInfos) => {
        for (let hotspotId of query) {
            try {
                return sceneInfos.hotspots[hotspotId][hotspotId];
            } catch (e) {
                continue
            }
        }
    }

    GoOrthographicsView = () => {
        this.setState(STATE.FLOORPLAN);

        let wait = []
        wait.push(this.leftScene.movingController.GoOrthographicsView());
        wait.push(this.rightScene.movingController.GoOrthographicsView());

        Promise.all(wait).then(() => {
            this.setState(STATE.FLOORPLAN, false);
        });
    }

    transition2Perspective = () => {
        this.setState(STATE.FPVIEW);

        let wait = []
        wait.push(this.leftScene.movingController.transition2Perspective());
        wait.push(this.rightScene.movingController.transition2Perspective());

        Promise.all(wait).then(() => {
            this.setState(STATE.FPVIEW, false);
        });
    }

    top2HotSpot = (hotspot) => {
        const { viewButton, building } = store.getState();
        const [curFloor, newFloor] = StoreGetters.getFloorId(hotspot);

        let curIdx = -1;
        building.floorList.forEach((floor, index) => {
            if (floor.id == newFloor) {
                curIdx = index;
                // store.dispatch(actions.setCurrentRenderIndex(index));
            }
        })

        this.changeFloor(curIdx).then(() => {
            let srcIsOthographicCamera = viewButton.viewState === STATE.FLOORPLAN

            this.setState(STATE.FPVIEW);

            let query = StoreGetters.getCorespondHotspotIds(hotspot);

            let leftHotspot = this.getCorespondHotspot(query, this.leftScene.currentSceneInfo);
            let rightHotspot = this.getCorespondHotspot(query, this.rightScene.currentSceneInfo);

            const { hotspotId } = leftHotspot;
            store.dispatch(actions.setCurrentHotspot(hotspotId));

            let wait = []
            wait.push(this.leftScene.movingController.top2HotSpot(leftHotspot, srcIsOthographicCamera));
            wait.push(this.rightScene.movingController.top2HotSpot(rightHotspot, srcIsOthographicCamera));

            Promise.all(wait).then(() => {
                this.setState(STATE.FPVIEW, false);
            });
        });
    }

    hotspot2Hotspot = (hotspot, throughWallsetting = { enable: false }) => {
        this.setState(STATE.FPVIEW);

        let query = StoreGetters.getCorespondHotspotIds(hotspot);

        let leftHotspot = this.getCorespondHotspot(query, this.leftScene.currentSceneInfo);
        let rightHotspot = this.getCorespondHotspot(query, this.rightScene.currentSceneInfo);

        const { hotspotId } = leftHotspot;
        store.dispatch(actions.setCurrentHotspot(hotspotId));

        let wait = []
        wait.push(this.leftScene.movingController.hotspot2Hotspot(leftHotspot, throughWallsetting));
        wait.push(this.rightScene.movingController.hotspot2Hotspot(rightHotspot, throughWallsetting));

        Promise.all(wait).then(() => {
            this.leftScene.updateRuler();
            this.rightScene.updateRuler();
            this.setState(STATE.FPVIEW, false);
        });
    }

    BackToStartPos = () => {
        this.setState(STATE.TOPVIEW);

        let wait = []
        wait.push(this.leftScene.movingController.BackToStartPos());
        wait.push(this.rightScene.movingController.BackToStartPos());

        Promise.all(wait).then(() => {
            this.setState(STATE.TOPVIEW, false);
        });
    }

    hotspot2AnyHotspot = (hotspot) => {

        // const [curFloor, newFloor] = StoreGetters.getFloorId(hotspot);
        // if (curFloor != newFloor) {
        //     store.dispatch(actions.setCurrentFloorId(newFloor));
        // }

        this.setState(STATE.FPVIEW);

        let query = StoreGetters.getCorespondHotspotIds(hotspot);

        // console.log(query[0]);

        let leftHotspot = this.getCorespondHotspot(query, this.leftScene.currentSceneInfo);
        let rightHotspot = this.getCorespondHotspot(query, this.rightScene.currentSceneInfo);

        const { hotspotId } = leftHotspot;
        store.dispatch(actions.setCurrentHotspot(hotspotId));

        let wait = []
        wait.push(this.leftScene.movingController.hotspot2AnyHotspot(leftHotspot));
        wait.push(this.rightScene.movingController.hotspot2AnyHotspot(rightHotspot));

        return Promise.all(wait).then(() => {
            this.setState(STATE.FPVIEW, false);
        });
    }

    GoToLastDownViewPos = () => {
        const { viewButton } = store.getState();
        let srcIsOthographicCamera = viewButton.viewState === STATE.FLOORPLAN

        this.setState(STATE.FPVIEW);

        let wait = []
        wait.push(this.leftScene.movingController.GoToLastDownViewPos(srcIsOthographicCamera));
        wait.push(this.rightScene.movingController.GoToLastDownViewPos(srcIsOthographicCamera));
        console.log(wait)

        Promise.all(wait).then(() => {
            this.setState(STATE.FPVIEW, false);
        }, () => {
            // onRejected
            if (srcIsOthographicCamera)
                this.setState(STATE.FLOORPLAN, false);
            else
                this.setState(STATE.TOPVIEW, false);
        });
    }
}

const SynchronizeController = new TwoSceneSynchronizeController();
export {
    STATE,
    SynchronizeController
}