import anime from 'animejs/lib/anime.es';
import * as THREE from 'three';

const duration = 1500;
const controlBackVecDistance = 0.7;
const controlBackVecHeight = 0.5;

function posEasing(t) {
  return t < 0.5 ? 8 * t ** 4 : 1 - 8 * (t - 1) ** 4;
}

function rotEasing(t) {
  const time = t - 1;
  return time * time * time + 1;
}

const clip = (cameraControls,dollhousesControls) => {
  dollhousesControls.setAllHotspotOpacity(0);
  dollhousesControls.setAllMeshOpacity(1);

  cameraControls.setGoinglimit(true);

  //const srcPosition = cameraControls.getCamerPosition();
  const srcPosition = new THREE.Vector3(0, 0, 0);
  const srcPolarAngle = cameraControls.polarAngle;

  const backwardvec3 = new THREE.Vector3(0, 0, controlBackVecDistance);
  backwardvec3.applyAxisAngle(
    new THREE.Vector3(0, 1, 0),
    cameraControls.azimuthAngle
  );

  const dstPosition = new THREE.Vector3().addVectors(srcPosition, backwardvec3);
  dstPosition.y = srcPosition.y + controlBackVecHeight;

  const dstPolarAngle = Math.atan2(
    new THREE.Vector2(
      dstPosition.x - srcPosition.x,
      dstPosition.z - srcPosition.z
    ).length(),
    dstPosition.y - srcPosition.y
  );

  // 控anime.js開始動畫
  const timeline = anime.timeline({
    autoplay: false,
    duration,
  });

  timeline.add(
    {
      update(anim) {
        const posEasingPercentage = posEasing(anim.progress / 100);
        cameraControls.setCamerPos(
          new THREE.Vector3().lerpVectors(
            srcPosition,
            dstPosition,
            posEasingPercentage
          ),
          true
        );
      },
    },
    0
  );
  const camControl = cameraControls;
  timeline.add(
    {
      update(anim) {
        const rotEasingPercentage = rotEasing(anim.progress / 100);
        camControl.polarAngle =
          (1 - rotEasingPercentage) * srcPolarAngle +
          rotEasingPercentage * dstPolarAngle;
      },
    },
    0
  );

  timeline.play();

  timeline.finished.then(() => {
    cameraControls.setTopViewLimit(srcPosition);
  });

  return timeline.finished;
};

export default clip;
