const RenderOrder = {

    tagGroup: 22,
    fpMesh: 2,

    fadeOut: 10,

    room: 15,

    icon: 16,
    hotspot: 17,
    door: 18,
    ruler: 19,
    hotspotRecord: 25,
    mouseHint: 26,

    floorPlanPlane: 49,
    floorPlanDoor: 50,
    floorPlanRoomLine: 51,


};

export { RenderOrder as default };