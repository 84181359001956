import anime from 'animejs/lib/anime.es';
import * as THREE from 'three';

const clip = (
  cameraControls,
  transitionMesh,
  destinationHotSpot,
  throughWallInfo,
  sceneInfo
) => {
  const { hotspots, roomGroup } = sceneInfo;
  const srcPosition = cameraControls.getCamerPosition();

  const hotSpotPos = new THREE.Vector3(0, 0, 0);
  destinationHotSpot.mesh.getWorldPosition(hotSpotPos);
  console.log("destinationHotSpot", hotSpotPos)
  const { mainRoomId, hotspotId } = destinationHotSpot;
  const dstPosition = new THREE.Vector3(
    hotSpotPos.x,
    hotSpotPos.y +
      hotspots[mainRoomId][hotspotId].cameraHeight * roomGroup.scale.y,
    hotSpotPos.z
  );

  const { blackInterval, enable } = throughWallInfo;
  function calPosition() {
    const calPositionVec = new THREE.Vector3().lerpVectors(
      srcPosition,
      dstPosition,
      transitionMesh.time
    );

    if (enable === true) {
      if (transitionMesh.time < blackInterval[0]) {
        const t = 1 - transitionMesh.time / blackInterval[0];
        transitionMesh.blackFactor = t;
      } else if (transitionMesh.time > blackInterval[1]) {
        const t =
          (transitionMesh.time - blackInterval[1]) / (1 - blackInterval[1]);
        transitionMesh.blackFactor = t;
      } else {
        cameraControls.renderer.setClearColor(0x000000, 1.0);
        cameraControls.renderer.clearColor();
        transitionMesh.blackFactor = 0;
      }
    }

    cameraControls.setCamerPos(calPositionVec, true);
  }

  // 控anime.js開始動畫
  const timeline = anime.timeline({
    autoplay: false,
    duration: srcPosition.distanceTo(dstPosition) * 4000,
  });

  timeline.add(
    {
      targets: transitionMesh,
      time: [0, 1],
      easing: 'easeInOutSine',
      update: calPosition,
    },
    0
  );

  timeline.play();

  timeline.finished.then(() => {
    transitionMesh.setdst2src();
  });

  return timeline.finished;
};

export default clip;
