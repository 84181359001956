import React from 'react';
import ThreeRenderScene from 'services/3dviewer';

import { CanvasCSS, VIEW } from '../config';

export default class ThreeScene extends React.Component {
  constructor(props) {
    super(props);
    let style = CanvasCSS.whole;
    if (!this.props.isMain) {
      style = CanvasCSS.none;
    }

    this.threeScene = null;
    this.canvasStyle = style;
  }

  render() {

    let newStyle = this.updateCanvasStyle();
    if (newStyle !== this.canvasStyle) {
      this.canvasStyle = newStyle;
    }

    return (
      <>
        <canvas
          className={this.canvasStyle}
          ref={ref => {
            this.canvas = ref;
          }}
        ></canvas>
      </>
    );
  }
  handleResize = e => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  componentDidMount() {
    this.threeScene = new ThreeRenderScene(this.canvas, this.props.isMain);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    this.threeScene.unMount();
  }

  componentDidUpdate() {
    this.threeScene.canvasResize();
  }

  updateCanvasStyle() {
    let newStyle = null;
    if (this.props.RWD == 'PC') {
      switch (this.props.mode) {
        case VIEW.SINGLE:
          if (this.props.isMain) {
            newStyle = CanvasCSS.whole;
          } else {
            newStyle = CanvasCSS.none;
          }
          break;
        case VIEW.DOUBLE:
          if (this.props.isMain) {
            newStyle = CanvasCSS.left;
          } else {
            newStyle = CanvasCSS.right;
          }
          break;
        case VIEW.RIGHTFULL:
          if (this.props.isMain) {
            newStyle = CanvasCSS.none;
          } else {
            newStyle = CanvasCSS.whole;
          }
          break;
        default:
          if (this.props.isMain) {
            newStyle = CanvasCSS.whole;
          } else {
            newStyle = CanvasCSS.none;
          }
          break;
      }
    } else {
      switch (this.props.mode) {
        case VIEW.SINGLE:
          if (this.props.isMain) {
            newStyle = CanvasCSS.whole;
          } else {
            newStyle = CanvasCSS.none;
          }
          break;
        case VIEW.DOUBLE:
          if (this.props.isMain) {
            newStyle = CanvasCSS.leftMobile;
          } else {
            newStyle = CanvasCSS.rightMobile;
          }
          break;
        case VIEW.RIGHTFULL:
          if (this.props.isMain) {
            newStyle = CanvasCSS.none;
          } else {
            newStyle = CanvasCSS.whole;
          }
          break;
        default:
          if (this.props.isMain) {
            newStyle = CanvasCSS.whole;
          } else {
            newStyle = CanvasCSS.none;
          }
          break;
      }
    }


    return newStyle;
  }
}
