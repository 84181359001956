import React, { Component } from 'react';
import store from 'store';
import qrcode from 'qrcode-generator/qrcode.js';
import APIService from 'services/cloudApi.js';
import cross from 'asset/image/cross.svg';

export default class QRCodeBoard extends Component {
  constructor(props) {
    super(props);
    this.qrcodeImg = null;
  }

  render() {
    const { parent } = this.props;
    return (
      <div className={'qrcode-board'}>
        <div className={'qrcode-board-background'}></div>

        <div className={'qrcode-board-image'}>
          <button
            className={'qrcode-close'}
            onClick={() => parent.triggerQRCodeBoard()}
          >
            <img src={cross} alt="close" />
          </button>
          <img
            ref={ref => (this.qrcodeImg = ref)}
            alt="qrcode showing in here"
          />
          <h3>掃描QR Code，馬上於您的手機上體驗虛擬實境(VR)</h3>
        </div>
      </div>
    );
  }

  async componentDidMount() {
    var typeNumber = 0;
    var errorCorrectionLevel = 'L';
    var qr = qrcode(typeNumber, errorCorrectionLevel);

    const { id } = store.getState();
    let qrUrl = await APIService.fetchQRCodeUrl(id);
    console.log(qrUrl);
    qr.addData(qrUrl);
    // console.log(qr);
    qr.make();
    let src = qr.createDataURL();
    this.qrcodeImg.src = src;
  }
}
