import React, { Component } from 'react';
import store from 'store';
import actions from 'store/actions';
import StoreGetters from 'store/store-getters';
import {
  STATE,
  SynchronizeController,
} from 'services/3dviewer/moving-controls/synchronizeController';
import {
  createObjectManager,
  ObjectManagers,
} from 'services/3dviewer/object-manager';

import ThreeScene from './threeScene';
import StyleSelectBoard from './styleSelectBoard';
import StyleSelectionMobile from './styleSelectionMobile';
import StyleSelection from './styleSelection';
import SelectionList from './selectionList';
import ToolBar from './toolbar';
import ToolBarMobile from './toolbarMobile';
import QRCodeBoard from './QRCodeBoard';
import ViewChangeMenu from './viewChangeMenu';
import RoomTag from './roomtag';
import Ruler from './ruler';
import VerifySection from './verifySection';

import { VIEW } from './config';
import Waterprint from 'asset/image/waterprint.png';
import loading from 'asset/image/loading7.gif';
import './css/viewer.scss';

const waitTime = 500; // 0.5s

export default class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewLoadCompleted: false,
      styleLoadCompleted: true,
      view: VIEW.SINGLE, //初始View模式
      openQRCodeBoard: false,
      RWD: 'PC',
      isNotFirst: false
    };

    this.rulerStatus = false;
    this.cameraHeight = 160
    this.isMultiStyle = false;
    console.log('version : 20210708/ea31d1f6');
  }

  async loadData(callback) {
    const { building } = store.getState();

    Object.keys(building.floorList[0].labels).map((labelid, index)=>{
      if(index == 0){
        this.cameraHeight = building.floorList[0].labels[labelid].cameraHeight * 100
      }
    })

    const Mapping = {};
    ObjectManagers.length = 0;
    for (let index in building.floorList) {
      try {
        const {
          dollHouseCubemapsData,
          hierarchy,
          labels,
          doorModelData,
          roomModelData,
          id,
        } = building.floorList[index];
        let floorID = id;
        if (hierarchy) Mapping[floorID] = {};
        for (let i = 0; i < hierarchy.length; i++) {
          Mapping[floorID][i] = createObjectManager();
          const ObjectManager = ObjectManagers[Mapping[floorID][i]];
          SynchronizeController.setSceneInfo(
            await ObjectManager.init(
              hierarchy[i].panoramas,
              roomModelData,
              doorModelData,
              labels,
              dollHouseCubemapsData
            ),
            Mapping
          );
        }
      } catch (e) {
        continue;
      }
    }

    callback();
  }

  async componentDidMount() {
    SynchronizeController.setRenderScenes(
      this.leftScene.threeScene,
      this.rightScene.threeScene
    );
    this.loadData(() => {
      SynchronizeController.init(
        this.props.isMultiFloor,
        () => {
          this.props.onReady();
          this.setState({ viewLoadCompleted: true });
        },
        this.props.cache
      );
    });

    let viewVariable = {
      isDrawingRuler: false,
      isCameraMoving: false,
    };

    store.dispatch(actions.setViewVariable(viewVariable));

    window.addEventListener('resize', this.handleRWD);
    window.addEventListener('keydown', this.onKeyDown, false);
    this.handleRWD();

    // SynchronizeController.changeStyle(1);
    const { queryStringList } = store.getState();
    // console.log(queryStringList);
    if (queryStringList.style == 'true') {
      this.changeView(VIEW.RIGHTFULL);
    }

    if (queryStringList.sceneMode == 'single') {
      this.changeView(VIEW.SINGLE);
    }else if(queryStringList.sceneMode == 'double'){
      this.changeView(VIEW.DOUBLE);
    }
    
    const { styles } = StoreGetters.getCurrentRenderFloorData();
    if (styles.length > 1) {
      this.isMultiStyle = true;
    }else{
      this.changeView(VIEW.SINGLE)
    }
  }

  onKeyDown = e => {
    console.log(e.keyCode);
    // switch (e.keyCode) {
    //   case 65: //  a key
    //     this.changeView(VIEW.RIGHTFULL);
    //     break;
    //   case 83: //  S key
    //     this.changeView(VIEW.SINGLE);
    //     break;
    //   case 68: //  D key
    //     this.changeView(VIEW.DOUBLE);
    //     break;
    //   // case
    // }
  };

  handleRWD = () => {
    // console.log(
    //   /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   )
    // );
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.setState({
        RWD: 'Mobile',
      });
    } else {
      this.setState({
        RWD: 'PC',
      });
    }
  };

  changeSecondStyle = index => {
    if (this.state.viewLoadCompleted) {
      this.setState({
        viewLoadCompleted: false,
        styleLoadCompleted: false
      });
      const { styles } = StoreGetters.getCurrentRenderFloorData();
      if (styles.length === 1) {
        alert('資料只有一個style');
        return;
      }

      const wait = SynchronizeController.changeStyle(index);
      // eslint-disable-next-line
      if (wait != undefined) {
        wait.then(() => {
          this.setState({
            viewLoadCompleted: true,

          });
          setTimeout(() => {
            this.setState({
              styleLoadCompleted: true,
            })
          }, waitTime)
        });
      }
    }
  };

  changeView = view => {
    this.setState({
      view: view,
      styleLoadCompleted: false
    });

    setTimeout(() => {
      this.setState({
        styleLoadCompleted: true,

      })
    }, waitTime)
  };

  triggerQRCodeBoard() {
    this.setState({
      openQRCodeBoard: !this.state.openQRCodeBoard,
    });
  }

  changeFloor(index) {
    SynchronizeController.changeFloor(index);
    // this.setState({ currentFloorIndex: index });
  }

  render() {
    const { data } = StoreGetters.getAllFloorData();

    const { viewLoadCompleted, isNotFirst } = this.state;
    const roomTagsLeft = [];
    const roomTagsRight = [];
    const wallRulerleft = [];

    if (viewLoadCompleted) {
      if (this.leftScene) {
        const Obj_leftInfo = this.leftScene.threeScene.currentSceneInfo;
        if (this.state.view == VIEW.SINGLE || this.state.view == VIEW.DOUBLE) {
          // room tag
          const roomNameLeft = Object.keys(Obj_leftInfo.rooms);
          roomNameLeft.forEach((id, index) => {
            const group = data.group[index];
            if (group.info.isShowing) {
              roomTagsLeft.push(
                <RoomTag
                  room={Obj_leftInfo.rooms[id]}
                  key={id}
                  isMain={true}
                  mode={this.state.view}
                  type={this.state.RWD}
                  scene={SynchronizeController.leftScene}
                  isShowing={group.info.isShowing}
                />
              );
            }
          });
        }

        // ruler
        let rulerArrays = Obj_leftInfo.rulerGroup.map(group => group.children);
        const rulerMeshes = [].concat.apply([], rulerArrays);
        rulerMeshes.forEach((mesh, index) => {
          wallRulerleft.push(
            <Ruler
              cameraHeight={this.cameraHeight}
              mesh={mesh}
              key={index}
              mode={this.state.view}
              type={this.state.RWD}
              scene={this.leftScene.threeScene}
            />
          );
        });
      }

      if (this.rightScene) {
        if (
          this.state.view == VIEW.RIGHTFULL ||
          this.state.view == VIEW.DOUBLE
        ) {
          // room tag
          const Obj_rightInfo = this.rightScene.threeScene.currentSceneInfo;
          const roomNameRight = Object.keys(Obj_rightInfo.rooms);

          roomNameRight.forEach((id, index) => {
            const group = data.group[index];
            if (group.info.isShowing) {
              roomTagsRight.push(
                <RoomTag
                  room={Obj_rightInfo.rooms[id]}
                  key={id}
                  isMain={false}
                  mode={this.state.view}
                  type={this.state.RWD}
                  scene={SynchronizeController.rightScene}
                />
              );
            }
          });
        }
      }
    }

    return (
      <div className="viewerContainer">
        {this.state.RWD == 'PC' ? (
          <>
            <ThreeScene
              isMain={true}
              mode={this.state.view}
              RWD={this.state.RWD}
              ref={ref => {
                this.leftScene = ref;
              }}
            />
            <ThreeScene
              isMain={false}
              mode={this.state.view}
              RWD={this.state.RWD}
              ref={ref => {
                this.rightScene = ref;
              }}
            />
            <SelectionList
              ref={ref => {
                this.selectionList = ref;
              }}
              parent={this}
            />
            <VerifySection />
            <div className="waterprint">
              <img src={Waterprint} alt="waterprint" />
            </div>

            {viewLoadCompleted && wallRulerleft}
            {viewLoadCompleted && roomTagsLeft}
            {viewLoadCompleted && roomTagsRight}
            {
              this.isMultiStyle && <StyleSelection parent={this} mode={this.state.view} />
            }
            {(viewLoadCompleted || isNotFirst) && (
              <ViewChangeMenu
                viewerCameraController={
                  this.leftScene.threeScene.viewerCameraController
                }
                objectManager={ObjectManagers[0]}
                parent={this}
                type={this.state.RWD}
                display={viewLoadCompleted}
              />
            )}
            <ToolBar
              mode={this.state.view}
              parent={this}
              leftviewerCameraController={
                this.leftScene != null
                  ? this.leftScene.threeScene.viewerCameraController
                  : null
              }
              rightviewerCameraController={
                this.leftScene != null
                  ? this.rightScene.threeScene.viewerCameraController
                  : null
              }
            />
            {this.state.openQRCodeBoard === true && (
              <QRCodeBoard parent={this} />
            )}
            {
              this.state.styleLoadCompleted == false && <div className="loadingAnim">
                <img src={loading} />
              </div>
            }
          </>
        ) : (
          <>
            <ThreeScene
              isMain={true}
              mode={this.state.view}
              RWD={this.state.RWD}
              ref={ref => {
                this.leftScene = ref;
              }}
            />
            <ThreeScene
              isMain={false}
              mode={this.state.view}
              RWD={this.state.RWD}
              ref={ref => {
                this.rightScene = ref;
              }}
            />
            <SelectionList
              ref={ref => {
                this.selectionList = ref;
              }}
              parent={this}
            />
            <VerifySection />
            <div className="waterprint">
              <img src={Waterprint} alt="waterprint" />
            </div>
            {viewLoadCompleted && wallRulerleft}
            {viewLoadCompleted && roomTagsLeft}
            {viewLoadCompleted && roomTagsRight}
            {
              this.isMultiStyle && <StyleSelectionMobile parent={this} mode={this.state.view} />
            }
            {(viewLoadCompleted || isNotFirst) && (
              <ViewChangeMenu
                viewerCameraController={
                  this.leftScene.threeScene.viewerCameraController
                }
                objectManager={ObjectManagers[0]}
                parent={this}
                type={this.state.RWD}
                display={viewLoadCompleted}
              />
            )}
            <ToolBarMobile
              mode={this.state.view}
              parent={this}
              viewerCameraController={
                this.leftScene != null
                  ? this.leftScene.threeScene.viewerCameraController
                  : null
              }
            />
            {this.state.openQRCodeBoard === true && (
              <QRCodeBoard parent={this} />
            )}
            {
              this.state.styleLoadCompleted == false && <div className="loadingAnim">
                <img src={loading} />
              </div>
            }
          </>
        )}
      </div>
    );
  }
}
