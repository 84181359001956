import * as THREE from 'three';
import StoreGetters from 'store/store-getters';
import store from 'store';
// import actions from 'store/actions';
import { loadTexture } from '../../loaders/texture-loader';
import { loadCubeTexture } from '../../loaders/cubemap-texture-loader';
import RenderOrder from '../object-manager/mat-manager';
import moveCamera2InitialPose from './clips/Hotspot2Top';
import top2hotspot from './clips/Top2Hotspot';
import hotspot2hotspot from './clips/Hotspot2Hotspot';
import RotateCameraAround from './clips/RotateCameraAround';
import TransitionCameraFov from './clips/FloorPlan2Topview';
import Move2TopCenter from './clips/Move2FloorPlan';
import Move2FpFromOrtho from './clips/Move2FpFromFloorPlan';
import TransitionFloorplanAlpha from './clips/TransitionFloorplanAlpha';
import carrier from '../carrier';
import RecordHintPointController from './RecordHintPointController';
import { STATE } from './synchronizeController';
// import { STATE, SynchronizeController } from './synchronizeController';
// import { createViewBtn, updateViewBtn } from './utils'

// 用來控制hotspot間的移動及3D/CubeMap切換
export default class MovingController {
    constructor(scene) {

        this.sourceHotSpot = null;
        // 現在攝影機所在hotspot null表示在在topview
        this.destinationHotspot = null;
        // 上一次回到topView前的站點hotspot object (並非在行走視角的起始位置)
        this.lastDownViewHotspot = null;
        // 判斷攝影機狀態
        this.nowIsTopView = true; // 判斷,現在攝影機是否移動回topView
        this.firstMove2MainRoom = false; // 進入網頁第一次走動開關

        this.viewerCameraController = null;
        this.mouseHintController = null;

        this.initFpMesh = this.initFpMesh.bind(this);
        this.BackToStartPos = this.BackToStartPos.bind(this);
        this.GoToLastDownViewPos = this.GoToLastDownViewPos.bind(this);
        this.GoOrthographicsView = this.GoOrthographicsView.bind(this);
        this.transition2Perspective = this.transition2Perspective.bind(this);
        this.hotspot2AnyHotspot = this.hotspot2AnyHotspot.bind(this);
        this.hotspot2Hotspot = this.hotspot2Hotspot.bind(this);
        this.top2HotSpot = this.top2HotSpot.bind(this);

        this.fpviewMesh = null;
    }

    getTextureInfo(targetHospot) {
        const { rooms, roomGroup } = this.sceneInfo;
        const room = rooms[targetHospot.mainRoomId];

        const hotSpotWorldPos = new THREE.Vector3();
        targetHospot.mesh.getWorldPosition(hotSpotWorldPos);
        const textureConfig = {
            textureId: targetHospot.hotspotId,
            x: hotSpotWorldPos.x,
            y: hotSpotWorldPos.y + targetHospot.cameraHeight * roomGroup.scale.y,
            z: hotSpotWorldPos.z,
            rotation: room.mesh.rotation.y +
                THREE.Math.degToRad(targetHospot.rotateY) +
                targetHospot.manhattanRotation,
        };

        return textureConfig;
    }

    GoOrthographicsView() {
        this.recordFPInfoWhenBackToTop();

        this.viewerCameraController.controls.reset()

        let wait = []
        wait.push(Move2TopCenter(this.viewerCameraController, 5, this.objManager));
        wait.push(TransitionFloorplanAlpha('fadeIn', this.objManager));

        return Promise.all(wait);
    }

    transition2Perspective() {
        this.viewerCameraController.controls.reset()

        let wait = []
        wait.push(TransitionCameraFov(this.viewerCameraController, 75, 1));
        wait.push(TransitionFloorplanAlpha('fadeOut', this.objManager));

        return Promise.all(wait);
    }

    initFpMesh(objManager, sceneInfo, destinationHotspot = null, lastDownViewHotspot = null, roomMeshArray = null) {
        //動態切換building
        const { viewButton } = store.getState();

        //更改hotspot成另一個buiding的
        this.destinationHotspot = destinationHotspot;
        this.lastDownViewHotspot = lastDownViewHotspot

        this.objManager = objManager;
        this.sceneInfo = sceneInfo;

        // 隱藏門
        this.objManager.setAllMeshOpacity(1);

        const { currentRoomMeshArray, mainroomHotspot } = sceneInfo;
        let transitionMeshArray = roomMeshArray || currentRoomMeshArray;
        this.fpviewMesh = new carrier.TextureTransitionCarrier(transitionMeshArray);
        this.fpviewMesh.mesh.renderOrder = RenderOrder.fpMesh;
        this.fpviewMesh.mesh.onAfterRender = () => {
            this.viewerCameraController.renderer.clearDepth();
        };

        let wait = null;
        // change fpmesh to new objectMangaer 
        if (viewButton === null) return;
        switch (viewButton.viewState) {
            case STATE.FPVIEW:
                this.destinationHotspot = this.destinationHotspot || mainroomHotspot;

                const cubemapUrls = StoreGetters.getCubemapUrl(this.destinationHotspot.hotspotId);
                wait = loadCubeTexture(cubemapUrls).then(texture => {
                    const config = this.getTextureInfo(this.destinationHotspot);
                    this.fpviewMesh.updateTexture(texture, config, false, true);

                    //set new camera pose
                    const { roomGroup } = sceneInfo;
                    const hotSpotPos = new THREE.Vector3(0, 0, 0);
                    this.destinationHotspot.mesh.updateWorldMatrix(true, true);
                    this.destinationHotspot.mesh.getWorldPosition(hotSpotPos);

                    const dstPosition = new THREE.Vector3(
                        hotSpotPos.x,
                        hotSpotPos.y + this.destinationHotspot.cameraHeight * roomGroup.scale.y,
                        hotSpotPos.z
                    );
                    this.viewerCameraController.setCamerPos(dstPosition, true)
                    texture = null;
                });
                objManager.setAllFloorsHotspotOpacity(0);
                objManager.setAllFloorsMeshOpacity(0.1);
                objManager.setAllFloorsBoundingAlpha(0);
                objManager.setAllHotspotOpacity(0.4);
                objManager.setAllMeshOpacity(0);
                break;
            case STATE.TOPVIEW:
                objManager.setAllFloorsHotspotOpacity(0);
                objManager.setAllFloorsMeshOpacity(0.1);
                objManager.setAllFloorsBoundingAlpha(0)
                objManager.setAllMeshOpacity(1);
                break;
            case STATE.FLOORPLAN:
                objManager.setAllFloorsHotspotOpacity(0);
                objManager.setAllFloorsMeshOpacity(0.1);
                objManager.setAllFloorsBoundingAlpha(0)
                objManager.setAllMeshOpacity(1);
                objManager.setAllBoundingAlpha(1)
                break;
            default:
                break;
        }

        return Promise.all([wait]);
    }

    // THREE.Mesh都create完後設定movingController的資訊
    init(viewCamerControls, mouseHintController) {
        this.viewerCameraController = viewCamerControls;

        this.mouseHintController = mouseHintController;

        // 紀錄點提示point(紀錄上一次DownView hotspot)
        this.recordHintPointController = new RecordHintPointController();
        this.recordHintPointController.initRecordHintPoint();

        
    }

    start(config = null) {
        if (config) {
            if (config.mode == STATE.FLOORPLAN) {
                const wait = this.startFromOrthographicsView();
                return [undefined, wait]
            }
            else {
                const [w, wait] = this.startFromHotspot(config.hotspot, config.rotation);
                return [undefined, wait]
            }
        }
        const rotateAnimationDone = RotateCameraAround(this.viewerCameraController);

        if (!this.firstMove2MainRoom) return [rotateAnimationDone, undefined];

        const { mainroomHotspot } = this.sceneInfo;
        const moveAnimationDone = rotateAnimationDone.then(() => {
            return this.top2HotSpot(mainroomHotspot);
        });

        return [rotateAnimationDone, moveAnimationDone]
    }

    startFromOrthographicsView = () => {
        this.recordFPInfoWhenBackToTop();

        this.objManager.setAllHotspotOpacity(0);
        this.objManager.setAllMeshOpacity(1);

        let dstFovDegree = 5;
        const dstFov = (dstFovDegree / 180) * Math.PI;
        this.viewerCameraController.fov = dstFov;

        const deserveScreenHeight = Math.tan(((75 / 180) * Math.PI) / 2) * 2;
        const distance = (1 / Math.tan(dstFov / 2)) * (deserveScreenHeight / 2);

        const dstPosition = new THREE.Vector3(0, distance, 0);
        this.viewerCameraController.camera.position.set(dstPosition.x, dstPosition.y, dstPosition.z);
        // this.viewerCameraController.setCamerPos(dstPosition, true);

        const dstPolarAngle = 0;
        this.viewerCameraController.polarAngle = dstPolarAngle;

        const dstAziAngle = 0;
        this.viewerCameraController.aziAngle = dstAziAngle;

        const lookAtPoint = this.viewerCameraController.getLookAtCenter().clone();
        lookAtPoint.y = 0;

        this.viewerCameraController.setLookAtCenter(lookAtPoint);
        this.viewerCameraController.setOrthographicLimit(lookAtPoint);

        this.objManager.setAllBoundingAlpha(1);

        return undefined;
    }

    startFromHotspot = (hotspot, rotation) => {
        const { rooms, doors, roomGroup, mainroomHotspot } = this.sceneInfo;

        hotspot = hotspot || mainroomHotspot;
        this.destinationHotspot = hotspot;

        // set camera pos
        const hotSpotPos = new THREE.Vector3(0, 0, 0);
        hotspot.mesh.updateWorldMatrix(true, true);
        hotspot.mesh.getWorldPosition(hotSpotPos);

        const dstPolarAngle = Math.PI / 2;
        this.viewerCameraController.polarAngle = dstPolarAngle;

        const dstPosition = new THREE.Vector3(
            hotSpotPos.x,
            hotSpotPos.y + hotspot.cameraHeight * roomGroup.scale.y,
            hotSpotPos.z
        );
        this.viewerCameraController.camera.position.set(dstPosition.x, dstPosition.y, dstPosition.z);
        if (rotation) {
            this.viewerCameraController.camera.rotation.set(rotation.x, rotation.y, rotation.z);
        }
        this.viewerCameraController.setFirstpersonViewLimit();

        rooms[hotspot.mainRoomId].mesh.renderOrder = RenderOrder.room;
        doors[hotspot.mainRoomId].mesh.renderOrder = RenderOrder.door;

        const cubemapUrls1x6 = StoreGetters.getCubemapUrl1x6(hotspot.hotspotId);

        const wait = loadTexture(cubemapUrls1x6).then(texture => {
            const config = this.getTextureInfo(hotspot);
            this.fpviewMesh.updateTexture(texture, config, true);


            // send request to get high resoulution
            const cubemapUrls = StoreGetters.getCubemapUrl(hotspot.hotspotId);
            const loadTexturePromise = loadCubeTexture(cubemapUrls);

            this.objManager.setAllHotspotOpacity(0.4);
            this.objManager.setAllMeshOpacity(0);
            this.nowIsTopView = false;

            loadTexturePromise.then(value => {
                const config = this.getTextureInfo(hotspot);
                this.fpviewMesh.updateTexture(value, config, false);
            });
        });

        return [undefined, wait]
    }

    top2HotSpot(hotspot, srcIsOthographicCamera = false) {
        if (hotspot === this.destinationHotspot) return;

        // 將hotspot暫存
        this.sourceHotSpot = this.destinationHotspot;
        this.destinationHotspot = hotspot;

        this.recordHintPointController.setRecordHintPointVisible(false);

        // back high resolution use low first to prevent internet lost

        const cubemapUrls1x6 = StoreGetters.getCubemapUrl1x6(hotspot.hotspotId);

        return loadTexture(cubemapUrls1x6).then(texture => {
            const config = this.getTextureInfo(hotspot);
            this.fpviewMesh.updateTexture(texture, config, true);


            // send request to get high resoulution
            const cubemapUrls = StoreGetters.getCubemapUrl(hotspot.hotspotId);
            const loadTexturePromise = loadCubeTexture(cubemapUrls);

            // start animation
            const animationDone = [];

            if (srcIsOthographicCamera) {
                animationDone.push(TransitionFloorplanAlpha('fadeOut', this.objManager));
                animationDone.push(
                    Move2FpFromOrtho(this.viewerCameraController, hotspot, this.objManager, this.sceneInfo)
                );
            } else {
                animationDone.push(
                    top2hotspot(
                        this.viewerCameraController,
                        hotspot,
                        srcIsOthographicCamera,
                        this.objManager,
                        this.sceneInfo
                    )
                );
            }

            const afterAnimationDone = Promise.all(animationDone).then(() => {
                this.objManager.setAllHotspotOpacity(0.4);
                this.objManager.setAllMeshOpacity(0);
                this.nowIsTopView = false;
            });

            Promise.all([loadTexturePromise, afterAnimationDone]).then(values => {
                const config = this.getTextureInfo(hotspot);
                this.fpviewMesh.updateTexture(values[0], config, false);
            });

            return afterAnimationDone
        });
    }

    hotspot2Hotspot(hotspot, throughWallsetting = { enable: false }) {
        if (hotspot === this.destinationHotspot) return;

        // 將hotspot暫存
        this.sourceHotSpot = this.destinationHotspot;
        this.destinationHotspot = hotspot;

        this.recordHintPointController.setRecordHintPointVisible(false);

        // back high resolution use low first to prevent internet lost
        const cubemapUrls1x6 = StoreGetters.getCubemapUrl1x6(
            this.sourceHotSpot.hotspotId
        );

        loadTexture(cubemapUrls1x6).then(textre => {
            const config = this.getTextureInfo(this.sourceHotSpot);
            this.fpviewMesh.updateTexture(textre, config, true);

            const cubemapUrls1x6dst = StoreGetters.getCubemapUrl1x6(
                hotspot.hotspotId
            );
            loadTexture(cubemapUrls1x6dst).then(textre2 => {
                const config2 = this.getTextureInfo(hotspot);
                this.fpviewMesh.addAnimeTexture(textre2, config2, true);
            });
        });

        const cubemapUrls = StoreGetters.getCubemapUrl(hotspot.hotspotId);
        const loadTexturePromise = loadCubeTexture(cubemapUrls);
        const animationDone = hotspot2hotspot(
            this.viewerCameraController,
            this.fpviewMesh,
            hotspot,
            throughWallsetting,
            this.sceneInfo
        )

        Promise.all([loadTexturePromise, animationDone]).then(values => {
            const config = this.getTextureInfo(hotspot);
            this.fpviewMesh.updateTexture(values[0], config, false);
        });

        return Promise.all([animationDone]);
    }

    /**
     * 按下home button觸發移動到topView
     */
    async BackToStartPos() {
        // 若在移動中or攝影機在topview 將無法點擊home鍵
        this.recordFPInfoWhenBackToTop();

        return moveCamera2InitialPose(this.viewerCameraController, this.objManager)
    }

    async recordFPInfoWhenBackToTop() {
        this.mouseHintController.MousePointVisible = false;
        this.objManager.resetAllModelTexture();

        if (this.destinationHotspot != null) {
            this.lastDownViewHotspot = this.destinationHotspot;
            this.recordHintPointController.changeRecordHintPointPos(
                this.lastDownViewHotspot
            );
            this.destinationHotspot = null;
        }

        this.recordHintPointController.setRecordHintPointVisible(true);
        this.nowIsTopView = true;
    }

    hotspot2AnyHotspot(hotspot) {
        if (hotspot === this.destinationHotspot) return;
        const { roomGroup } = this.sceneInfo;

        const currentPosition = new THREE.Vector3();
        hotspot.mesh.getWorldPosition(currentPosition);
        currentPosition.y += hotspot.cameraHeight * roomGroup.scale.y;

        const destinationPosition = new THREE.Vector3();
        this.destinationHotspot.mesh.getWorldPosition(destinationPosition);
        destinationPosition.y +=
            this.destinationHotspot.cameraHeight * roomGroup.scale.y;

        const cur2dst = new THREE.Vector3().subVectors(
            destinationPosition,
            currentPosition
        );

        const dst2cur = new THREE.Vector3().subVectors(
            currentPosition,
            destinationPosition
        );

        const distance = cur2dst.length();
        cur2dst.normalize();

        const cur2dstRaycaster = new THREE.Raycaster(
            currentPosition,
            cur2dst,
            0,
            distance
        );

        const intersectcur2dst = cur2dstRaycaster.intersectObject(
            this.fpviewMesh.mesh
        );

        const dst2curRaycaster = new THREE.Raycaster(
            destinationPosition,
            dst2cur,
            0,
            distance
        );

        const intersectdst2cur = dst2curRaycaster.intersectObject(
            this.fpviewMesh.mesh
        );

        if (intersectcur2dst.length === 0 && intersectdst2cur.length === 0) {
            return this.hotspot2Hotspot(hotspot);
        } else {
            let blackOut = 0.05;
            let blackIn = 0.95;

            if (intersectcur2dst[0] !== undefined) {
                blackOut = intersectcur2dst[0].distance / 2 / distance;
            }

            if (intersectdst2cur[0] !== undefined) {
                blackIn = (distance - intersectdst2cur[0].distance / 2) / distance;
            }

            return this.hotspot2Hotspot(hotspot, {
                enable: true,
                blackInterval: [blackOut, blackIn],
            });
        }
    }

    /**
     * 點擊walk button 觸發 回到上一次down view 位置
     */
    GoToLastDownViewPos(srcIsOthographicCamera) {
        return new Promise((resolve, reject) => {
            if (
                this.lastDownViewHotspot === null ||
                this.lastDownViewHotspot === undefined
            ) reject();
            else resolve(this.top2HotSpot(this.lastDownViewHotspot, srcIsOthographicCamera));
        });
    }
}