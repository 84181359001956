import React, { Component } from 'react';
import { DROPDOWN } from '../config';
import store from 'store';

export default class FloorSelectionList extends Component {
    constructor(props){
        super(props);
        let topOffset = 25;
        let leftOffset = 20;
        if(this.props.RWD === "Mobile")
        {
            topOffset = 25;
            // topOffset = 48;
            leftOffset = 10;
        }

        this.state = {
            mode: DROPDOWN.CLOSE,
            RWD: this.props.RWD,
            startIndex: 0,
            showLength: 4,
            indexInterval: [0, 1, 2, 3],
            topOffset: topOffset,
            leftOffset: leftOffset,
            buttonSize: this.props.RWD === "PC" ? [80, 35] : [80, 40],
            selectedBgColor: [255, 255, 255, 0.9],
            // selectedBgColor: [85, 85, 85, 0.6],
            // selectedTextColor: [255, 204, 51, 1.0],
            options: this.props.options,
        };
        this.updateList = this.updateList.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    updateList(options)
    {
        this.setState({
            options: options
        })
    }

    closeList()
    {
        this.handleClick("close");
    }

    handleClick = (value) => {

        const { currentRenderIndex } = store.getState();

        if(value === "open" || value === "close")
        {
            this.setState({
                mode: (value === "open") ? DROPDOWN.OPEN : DROPDOWN.CLOSE,
             })
        }
        else
        {
            if(value === "up" || value === "down")
            {
                let startIndex = this.state.startIndex;
                let showLength = this.state.showLength;
                let optionLength = this.state.options.length;
                if(value === "down" && startIndex < optionLength-showLength)
                {
                    startIndex = (startIndex + 1) % optionLength;
                }
                else if(value === "up" && startIndex > 0)
                    startIndex = ((startIndex - 1) + optionLength) % optionLength;
                let indexInterval = [];
                for(let i = 0; i < showLength; i++)
                {
                    let index = (startIndex + i) % optionLength;
                    indexInterval.push(index);
                }
                this.setState({
                    startIndex: startIndex,
                    indexInterval: indexInterval
                })
            }
            else
            {
                if(value !== currentRenderIndex)
                {
                    console.log("Select floor index: " + value);
                    this.props.parent.changeFloor(value);
                }
                this.setState({
                    mode: DROPDOWN.CLOSE
                })
            }
        }
    }

    render() {
        let optionLength = Math.min(this.state.options.length, this.state.showLength);
        // console.log(this.state.options);
        let downButtonPos = this.state.topOffset + (optionLength + 1) * this.state.buttonSize[1];
        let selectedBgColor = this.state.selectedBgColor;
        let selectedTextColor = this.state.selectedTextColor;
        let buttonSize = this.state.buttonSize;
        if(optionLength === 0)
        {
            return null;
        }
        let currentLabel = null;

        const { currentRenderIndex } = store.getState();
        if(currentRenderIndex != null && currentRenderIndex != -1)  currentLabel = this.state.options[currentRenderIndex].label

        return(
            <div>
                {this.state.RWD === "Mobile" && (
                    null
                    // <button className="show-dropdown-close-mobile-bt" 
                    //     style={{top: '0px', left: this.state.leftOffset+'px', width: buttonSize[0], height: buttonSize[1]}}
                    //     onClick={(event) => {
                    //         if(this.state.mode === DROPDOWN.CLOSE)
                    //             this.handleClick("open"); 
                    //         else
                    //             this.handleClick("close");
                    //         event.stopPropagation();
                    //     }}
                    // >
                    //     {/* <span className="dropdown-close-value">{currentLabel && currentLabel.length > 2 ? 
                    //                                             currentLabel.substr(0, 2) + "..." : 
                    //                                             currentLabel}</span> */}
                    //     <span className="dropdown-close-value">{currentLabel}</span>
                    //     <i className="dropdown-close-down"></i>
                    // </button>
                )}
                {this.state.mode === DROPDOWN.CLOSE && /*this.state.RWD === "PC" &&*/ (
                    <button className="show-dropdown-close" 
                        style={{top: this.state.topOffset+'px', left: this.state.leftOffset+'px', width: buttonSize[0], height: buttonSize[1]}}
                        onClick={(event) => {this.handleClick("open"); event.stopPropagation();}}
                    >
                        {/* <span className="dropdown-close-value">{currentLabel && currentLabel.length > 5 ?
                                                                currentLabel.substr(0, 5) + "..." :
                                                                currentLabel}</span> */}
                        <span className="dropdown-close-value">{currentLabel}</span>
                        <i className="dropdown-close-down"></i>
                    </button>
                )}
                {this.state.mode === DROPDOWN.OPEN && (
                    <div>
                        <button className="show-dropdown-open"
                                style={{top: this.state.topOffset+'px', left: this.state.leftOffset+'px', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, width: buttonSize[0], height: buttonSize[1]}}
                                onClick={(event) => {this.handleClick("up"); event.stopPropagation();}}
                        >
                            <i className="dropdown-open-up"></i>
                        </button>

                        {this.state.options.map((e, key) => {
                            let indexInterval = this.state.indexInterval;
                            if(indexInterval.indexOf(key) >= 0)
                            {
                                let offset = this.state.topOffset + (indexInterval.indexOf(key) + 1) * this.state.buttonSize[1];
                                if(key === currentRenderIndex)
                                {
                                    return <button key={key} 
                                                className = "dropdown-open-button" 
                                                style = {{top: offset+'px', 
                                                        left: this.state.leftOffset+'px',
                                                        backgroundColor: 'rgba('+selectedBgColor+')',
                                                        color: 'rgba('+selectedTextColor+')',
                                                        width: buttonSize[0],
                                                        height: buttonSize[1]
                                                }}
                                                onClick = {(event) => {this.handleClick(key); event.stopPropagation();}}
                                            >
                                                {/* {(this.state.RWD === "PC" && e.label.length > 5) ?
                                                    e.label.substr(0, 5) + "..." :
                                                    (this.state.RWD === "Mobile" && e.label.length > 3) ?
                                                    e.label.substr(0, 3) + "..." :
                                                    e.label 
                                                } */}
                                                <span className = "dropdown-open-value" style = {{
                                                    width: buttonSize[0] - 20,
                                                }}>{e.label}</span>
                                                <span className="dropdown-open-point"></span>
                                            </button> 
                                }
                                else
                                {
                                    return <button key = {key} 
                                                className = "dropdown-open-button" 
                                                style = {{top: offset+'px', left: this.state.leftOffset+'px', width: buttonSize[0], height: buttonSize[1]}}
                                                onClick = {(event) => {this.handleClick(key); event.stopPropagation();}}
                                            >
                                                {/* {(this.state.RWD === "PC" && e.label.length > 5) ?
                                                    e.label.substr(0, 5) + "..." :
                                                    (this.state.RWD === "Mobile" && e.label.length > 3) ?
                                                    e.label.substr(0, 3) + "..." :
                                                    e.label 
                                                } */}
                                                <span className = "dropdown-open-value" style = {{
                                                    width: buttonSize[0] - 20,
                                                }}>{e.label}</span>
                                            </button> 
                                }
                            } else {
                                return ''
                            }
                        })}

                        <button className="show-dropdown-open" 
                                style={{top: downButtonPos+'px', left: this.state.leftOffset+'px', borderTopLeftRadius: 0, borderTopRightRadius: 0, width: buttonSize[0], height: buttonSize[1]}}
                                onClick={(event) => {this.handleClick("down"); event.stopPropagation();}}
                        >
                            <i className="dropdown-open-down"></i>
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
