import React, { Component } from 'react';
import classNames from 'classnames';

import store from 'store/index';
import StoreGetters from 'store/store-getters';

import styleIcon from 'asset/image/icons-vr-icon.svg';
import closeIcon from 'asset/image/cross.svg';
import closeIconHover from 'asset/image/icons-close-hover.svg';
import splitIcon from 'asset/image/icons-split-screen.svg';
import splitSelectIcon from 'asset/image/icons-split-screen-select.svg';
import fullIcon from 'asset/image/icons-full-screen.svg';
import fullSelectIcon from 'asset/image/icons-full-screen-select.svg';
import { VIEW } from '../config';
import { STATE } from 'services/3dviewer/moving-controls/synchronizeController';

export default class StyleSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      styles: null,
      selectIndex: 1,
      isDoubleHover: false,
      isFullHover: false,
      isCloseHover: false
    };
  }

  componentDidMount() {
    store.subscribe(this.setStyleList);
  }

  changeStyle = mode => {
    this.props.parent.changeView(mode);
  };

  changeStyleIndex = index => {
    this.props.parent.changeSecondStyle(index);
    this.setState({
      selectIndex: index,
    });
  };

  setStyleList = () => {
    const floorStyle = StoreGetters.getCurrentRenderFloorStyles();

    const { viewButton } = store.getState();
    if (viewButton) {
      const { viewState } = viewButton;
      this.setState({
        styles: floorStyle,
        isFPview: viewState == STATE.FPVIEW,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.isFPview && (
          <>
            {this.props.mode == VIEW.SINGLE && (
              <div
                className="select-style-board"
                onClick={() => this.changeStyle(VIEW.DOUBLE)}
              >
                <img src={styleIcon} />
                <h3 className="select-style-button-name">VR美裝</h3>
              </div>
            )}
            {this.props.mode != VIEW.SINGLE && (
              <div className="select-style-board-open">
                <img src={styleIcon} />
                {this.state.styles != null &&
                  Object.keys(this.state.styles).map((b, index) => {
                    if (index != 0) {
                      var styleClass = classNames({
                        'select-style-button-name': true,
                        selected: this.state.selectIndex == index,
                      });
                      return (
                        <h3
                          key={index}
                          className={styleClass}
                          onClick={() => this.changeStyleIndex(index)}
                        >
                          {this.state.styles[b].name}
                        </h3>
                      );
                    }
                  })}
                <img
                  className="closeStyleIcon"
                  src={this.state.isCloseHover ? closeIconHover : closeIcon}
                  onClick={() => this.changeStyle(VIEW.SINGLE)}
                  onMouseEnter={() => {
                    this.setState({
                      isCloseHover: true
                    })
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      isCloseHover: false
                    })
                  }}
                />
              </div>
            )}
            {this.props.mode != VIEW.SINGLE && (
              <div className="select-style-board-iconlist">
                <div
                  className="select-style-board-iconblock"
                  onClick={() => this.changeStyle(VIEW.RIGHTFULL)}
                  onMouseEnter={() => {
                    this.setState({
                      isFullHover: true
                    })
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      isFullHover: false
                    })
                  }}
                >
                  <img
                    src={
                      this.props.mode == VIEW.RIGHTFULL || this.state.isFullHover == true
                        ? fullSelectIcon
                        : fullIcon
                    }
                  />
                </div>
                <div
                  className="select-style-board-iconblock"
                  onClick={() => this.changeStyle(VIEW.DOUBLE)}
                  onMouseEnter={() => {
                    this.setState({
                      isDoubleHover: true
                    })
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      isDoubleHover: false
                    })
                  }}
                >
                  <img
                    src={
                      this.props.mode == VIEW.DOUBLE || this.state.isDoubleHover == true
                        ? splitSelectIcon
                        : splitIcon
                    }
                  />
                </div>
              </div>
            )}
            {this.props.mode != VIEW.SINGLE && (
              <div
                className={
                  this.props.mode == VIEW.RIGHTFULL
                    ? 'select-style-signal-rightfull'
                    : 'select-style-signal-double'
                }
              >
                美裝後
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
