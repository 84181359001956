import React from 'react';
import { Trans } from 'react-i18next';
import { STATE } from 'services/3dviewer/moving-controls/synchronizeController';
import store from 'store';
import actions from 'store/actions';
import APIService from 'services/cloudApi.js';
import { useLocation } from 'react-router-dom';

import ruler from 'asset/image/icons-yc-ruler.svg';
import rulerUnusable from 'asset/image/icons-yc-ruler-unusable.svg';
import rulerSelect from 'asset/image/icons-yc-ruler-select.svg';
import zoomOut from 'asset/image/icons-yc-zoom-out.svg';
import zoomIn from 'asset/image/icons-yc-zoom-in.svg';
import QRCode from 'asset/image/icons-yc-qrcode.svg';

import zoomOutHover from 'asset/image/icons-yc-zoom-out-hover.svg';
import zoomInHover from 'asset/image/icons-yc-zoom-in-hover.svg';
import QRCodeHover from 'asset/image/icons-yc-qrcode-hover.svg';
import fullScreen from 'asset/image/fullScreen.svg';


export default class ToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.qrCodeUrl = '';
    this.state = {
      isFPview: false,
      isDrawRuler: false,
      isVerify: false,
      isRulerHover: false,
      isQRCodeHover: false,
      isZoomInHover: false,
      isZoomOutHover: false,
    };
  }

  storeDataChange = () => {
    const { viewButton, viewVariable } = store.getState();
    if (viewButton) {
      const { viewState } = viewButton;
      this.setState({
        isFPview: viewState == STATE.FPVIEW,
        isDrawRuler: viewVariable.isDrawingRuler,
      });
    }
  };

  rulerSwitch() {
    const { viewButton, viewVariable } = store.getState();
    const { viewState } = viewButton;
    if (viewState == STATE.FPVIEW) {
      viewVariable.isDrawingRuler = !viewVariable.isDrawingRuler;
      store.dispatch(actions.setViewVariable(viewVariable));
    }
  }

  zoomIn() {
    this.props.leftviewerCameraController.controls.zoomIn();
    this.props.rightviewerCameraController.controls.zoomIn();
  }

  zoomOut() {
    this.props.leftviewerCameraController.controls.zoomOut();
    this.props.rightviewerCameraController.controls.zoomOut();
  }

  linkToYcPage() {
    window.open(this.qrCodeUrl, '_blank').focus();
  }

  render() {
    const { parent } = this.props;
    // const toolbarClass = this.state.isFPview ?  : 'tool-bar-topview';
    let rulerImage = rulerUnusable;
    if (this.state.isFPview) {
      if (this.state.isDrawRuler) {
        rulerImage = rulerSelect;
      } else {
        rulerImage = ruler;
      }
    }
    return (
      <div className={'tool-bar'}>
        {
          this.state.isVerify == false && <>
            <button onClick={() => this.rulerSwitch()}
              onMouseEnter={() => {
                if (this.state.isFPview) {
                  this.setState({
                    isRulerHover: true
                  })
                }

              }}
              onMouseLeave={() => {
                if (this.state.isFPview) {
                  this.setState({
                    isRulerHover: false
                  })
                }
              }}
            >
              <img src={this.state.isRulerHover ? rulerSelect : rulerImage} className={this.state.isFPview && "activeToolbutton"} alt="ruler" />
            </button>
            <button
              className="mobile-hide-button"
              onClick={() => parent.triggerQRCodeBoard()}
              onMouseEnter={() => {
                this.setState({
                  isQRCodeHover: true
                })
              }}
              onMouseLeave={() => {
                this.setState({
                  isQRCodeHover: false
                })
              }}
            >
              <img src={this.state.isQRCodeHover ? QRCodeHover : QRCode} className={"activeToolbutton"} alt="QRCode" />
            </button>
          </>
        }

        <button onClick={() => this.zoomIn()}
          onMouseEnter={() => {
            this.setState({
              isZoomInHover: true
            })
          }}
          onMouseLeave={() => {
            this.setState({
              isZoomInHover: false
            })
          }}>
          <img src={this.state.isZoomInHover ? zoomInHover : zoomIn} className={"activeToolbutton"} alt="zoomIn" />
        </button>
        <button onClick={() => this.zoomOut()}
          onMouseEnter={() => {
            this.setState({
              isZoomOutHover: true
            })
          }}
          onMouseLeave={() => {
            this.setState({
              isZoomOutHover: false
            })
          }}>
          <img src={this.state.isZoomOutHover ? zoomOutHover : zoomOut} className={"activeToolbutton"} alt="zoomOut" />
        </button>
      </div>
    );
  }

  async componentDidMount() {
    const { id } = store.getState();
    this.unsubscribe = store.subscribe(this.storeDataChange); // add moniter changes from store and set listener
    this.storeDataChange();

    this.qrCodeUrl = await APIService.fetchQRCodeUrl(id);

    APIService
      .fetchCaseStatus(id)
      .then(result => {
        console.log("system result", result)
        if (
          result.detailStatus == 'pendingAcceptance' ||
          result.detailStatus == 'acceptanceFailed' ||
          result.detailStatus == 'acceptancePassed'
        ) {
          this.setState({
            isVerify: true
          })
        }
      })
      .catch(err => console.log(err));
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
}
