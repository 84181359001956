import React, { Component } from 'react';
import store from 'store';

export default class MultiFloorDropdown extends Component {
  constructor(props) {
    super(props);
    let options = [{ value: -1, label: '全部' }];
    options = options.concat(this.props.options);

    this.state = {
      RWD: this.props.RWD,
      options: options,
      parent: this.props.parent,
      showLength: 5,
    };
  }

  handleClick = value => {
    const { currentRenderIndex } = store.getState();
    let currentFloorIndex = currentRenderIndex;
    if (value === 'down') {
      if (currentFloorIndex < this.state.options.length - 2) {
        this.state.parent.changeFloor(currentFloorIndex + 1);
      }
    } else if (value === 'up') {
      if (currentFloorIndex > -1) {
        this.state.parent.changeFloor(currentFloorIndex - 1);
      }
    } else {
      if (value !== currentFloorIndex) {
        this.state.parent.changeFloor(value);
      }
    }
  };

  render() {
    let optionLength = Math.min(
      this.state.options.length,
      this.state.showLength
    );
    if (optionLength <= 2) return null;

    let listSize = [120, 600];
    let buttonUnselectSize = [85, 55];
    let buttonSelectSize = [100, 65];
    let buttonInterval = 40;
    let rwd = this.state.parent.state.RWD;

    if (rwd == 'Mobile') {
      listSize = [63, 280];
      buttonUnselectSize = [40, 26];
      buttonSelectSize = [47, 30];
      buttonInterval = 15;
    } else {
    }

    const { currentRenderIndex } = store.getState();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div
          className="multifloor-dropdown"
          style={{
            width: listSize[0],
            height: listSize[1],
          }}
        >
          <button
            style={{
              width: listSize[0],
              height: buttonUnselectSize[1],
              background: 'rgba(0, 0, 0, 0)',
              border: 0,
              color: 'white',
            }}
            onClick={event => {
              this.handleClick('up');
              event.stopPropagation();
            }}
          >
            <i className="multifloor-button-up"></i>
          </button>

          {this.state.options.map((e, index) => {
            let crntIndex = currentRenderIndex;
            let diff = index - 1 - crntIndex;
            if (Math.abs(diff) <= 2) {
              let offset =
                diff * (buttonUnselectSize[1] + buttonInterval) +
                listSize[1] / 2 -
                buttonSelectSize[1] / 2;
              let buttonClass =
                diff == 0 ? 'multifloor-button-select' : 'multifloor-button';
              let buttonSize =
                diff == 0 ? buttonSelectSize : buttonUnselectSize;
              return (
                <button
                  key={e.value}
                  className={buttonClass}
                  style={{
                    top: offset + 'px',
                    width: buttonSize[0] + 'px',
                    height: buttonSize[1] + 'px',
                  }}
                  onClick={event => {
                    this.handleClick(e.value);
                    event.stopPropagation();
                  }}
                >
                  {e.label}
                </button>
              );
            }
          })}

          <button
            style={{
              width: listSize[0],
              height: buttonUnselectSize[1],
              background: 'rgba(0, 0, 0, 0)',
              border: 0,
              color: 'white',
            }}
            onClick={event => {
              this.handleClick('down');
              event.stopPropagation();
            }}
          >
            <i className="multifloor-button-down"></i>
          </button>
        </div>
      </div>
    );
  }
}