import * as THREE from 'three';
import Shaders from 'services/shader';

function TextureTransitionCarrier(meshArray) {
  const geometry = new THREE.Geometry();

  meshArray.forEach(mesh => {
    geometry.merge(
      new THREE.Geometry().fromBufferGeometry(mesh.geometry),
      mesh.matrixWorld
    );
  });

  const cubeMapShaderConfig = new Shaders.CubemapShaderWorldPos();
  const highResolutionTextureCubeMapMat = new THREE.ShaderMaterial({
    fragmentShader: cubeMapShaderConfig.fragmentShader,
    vertexShader: cubeMapShaderConfig.vertexShader,
    uniforms: cubeMapShaderConfig.uniforms,
    transparent: true,
  });

  const altasShaderConfig = new Shaders.AltasShaderWorldPos();
  const lowResolutionTextureAltasMat = new THREE.ShaderMaterial({
    fragmentShader: altasShaderConfig.fragmentShader,
    vertexShader: altasShaderConfig.vertexShader,
    uniforms: altasShaderConfig.uniforms,
    transparent: true,
  });

  let currentTextureId = '';
  let currentTexture = null;
  let animeDstTextureId = '';
  let animeTexture = null;
  const mesh = new THREE.Mesh(geometry, lowResolutionTextureAltasMat);

  function addAnimeTexture(iTexture, mappingConfig1, isAltas) {
    const texture1 = iTexture;
    if (isAltas) {
      animeDstTextureId = mappingConfig1.textureId;
      mesh.material = lowResolutionTextureAltasMat;

      texture1.generateMipmaps = false;
      texture1.minFilter = THREE.NearestFilter;
      texture1.magFilter = THREE.NearestFilter;
      texture1.wrapS = THREE.ClampToEdgeWrapping;
      texture1.wrapT = THREE.ClampToEdgeWrapping;
    } else {
      return;
    }

    // release old texture and set a new one
    if (animeTexture != null) animeTexture.dispose();

    animeTexture = texture1;
    mesh.material.uniforms.tEquirect1.value = texture1;

    // set uniform
    mesh.material.uniforms.textureConfig1.value = new THREE.Vector4(
      mappingConfig1.x,
      mappingConfig1.y,
      mappingConfig1.z,
      mappingConfig1.rotation
    );
  }

  function updateTexture(iTexture, mappingConfig0, isAltas, forceUpdate = false) {
    let texture0 = iTexture;
    if (isAltas) {
      currentTextureId = mappingConfig0.textureId;
      mesh.material = lowResolutionTextureAltasMat;

      texture0.generateMipmaps = false;
      texture0.minFilter = THREE.NearestFilter;
      texture0.magFilter = THREE.NearestFilter;
      texture0.wrapS = THREE.ClampToEdgeWrapping;
      texture0.wrapT = THREE.ClampToEdgeWrapping;
    } else if (!isAltas) {
      // ignore come lag texture
      if (forceUpdate === true) {
        currentTextureId = mappingConfig0.textureId;
      }
      if (currentTextureId !== mappingConfig0.textureId) {
        return;
      }
      mesh.material = highResolutionTextureCubeMapMat;
    }

    // release old texture and set a new one
    if (currentTexture != null) currentTexture.dispose();

    currentTexture = texture0;
    mesh.material.uniforms.tEquirect0.value = texture0;

    // set uniform
    mesh.material.uniforms.textureConfig0.value = new THREE.Vector4(
      mappingConfig0.x,
      mappingConfig0.y,
      mappingConfig0.z,
      mappingConfig0.rotation
    );

    texture0.dispose();
    texture0 = null;
  }

  function getTime() {
    return mesh.material.uniforms.time.value;
  }
  function setTime(time) {
    mesh.material.uniforms.time.value = time;
  }

  function getBlack() {
    return mesh.material.uniforms.blackFactor.value;
  }
  function setBlack(time) {
    mesh.material.uniforms.blackFactor.value = time;
  }

  function setdst2src() {
    mesh.material.uniforms.tEquirect0.value =
      mesh.material.uniforms.tEquirect1.value;
    mesh.material.uniforms.textureConfig0.value =
      mesh.material.uniforms.textureConfig1.value;
    mesh.material.uniforms.time.value = 0;
    currentTextureId = animeDstTextureId;
  }

  function dispose(){
    geometry.dispose();
    highResolutionTextureCubeMapMat.dispose()
    lowResolutionTextureAltasMat.dispose()

  }

  this.setdst2src = setdst2src;
  this.getTime = getTime;
  this.setTime = setTime;

  this.updateTexture = updateTexture;
  this.addAnimeTexture = addAnimeTexture;
  this.mesh = mesh;

  this.dispose = dispose;

  Object.defineProperty(this, 'mesh', {
    value: mesh,
    enumerable: true,
  });

  Object.defineProperty(this, 'time', {
    get: getTime,
    set: setTime,
  });

  Object.defineProperty(this, 'blackFactor', {
    get: getBlack,
    set: setBlack,
  });
}

export default TextureTransitionCarrier;
