import { combineReducers } from 'redux';
import types from './mutation-types';

function building(iBuilding = null, action) {
    switch (action.type) {
        case types.SET_BUILDING:
            return action.building;
        default:
            return iBuilding;
    }
}

function id(iId = null, action) {
    switch (action.type) {
        case types.SET_ID:
            return action.id;
        default:
            return iId;
    }
}

function viewVariable(iViewVariable = null, action) {
    switch (action.type) {
        case types.SET_VIEW_VAR:
            return action.viewVariable;
        default:
            return iViewVariable;
    }
}

function currentRenderIndex(id = null, action) {
    switch (action.type) {
        case types.SET_CURRENT_RENDER_ID:
            return action.renderIndex;
        default:
            return id;
    }
}

function currentHotSpot(iHotspot = null, action) {
    switch (action.type) {
        case types.SET_CURRENT_HOTSPOT:
            return action.hotspot;
        default:
            return iHotspot;
    }
}

// function currentRenderFloorID(iFloor = null, action) {
//     switch (action.type) {
//         case types.SET_CURRENT_FLOORID:
//             return action.currentFloorID;
//         default:
//             return iFloor;
//     }
// }

function queryStringList(iString = null, action) {
    switch (action.type) {
        case types.SET_QUERY_STRING:
            return action.queryString;
        default:
            return iString;
    }
}

// function dollHouseCubemap(dollhouseCubemap = null, action) {
//   switch (action.type) {
//     case types.SET_DOLLHOUSE_CUBEMAP:
//       return action.dollhouseCubemap;
//     default:
//       return dollhouseCubemap;
//   }
// }
// function roomModels(roomModel = null, action) {
//   switch (action.type) {
//     case types.SET_ROOM_MODEL:
//       return action.roomModel;
//     default:
//       return roomModel;
//   }
// }
// function doorModels(doorModel = null, action) {
//   switch (action.type) {
//     case types.SET_DOOR_MODEL:
//       return action.doorModel;
//     default:
//       return doorModel;
//   }
// }
function viewButton(info = null, action) {
    switch (action.type) {
        case types.SET_VIEWBTN:
            return action.info;
        default:
            return info;
    }
}
// function labels(iLabels = null, action) {
//   switch (action.type) {
//     case types.SET_LABELS:
//       return action.labels;
//     default:
//       return iLabels;
//   }
// }
// function hierarchy(iHierarchy = null, action) {
//   switch (action.type) {
//     case types.SET_HIERARCHY:
//       return action.hierarchy;
//     default:
//       return iHierarchy;
//   }
// }

// function buildingId(iBuildingId = null, action) {
//   switch (action.type) {
//     case types.SET_BUILDINGID:
//       return action.buildingId;
//     default:
//       return iBuildingId;
//   }
// }
// function cubemapUrls(urls = null, action) {
//   switch (action.type) {
//     case types.SET_CUBEMAP_URLS:
//       return action.urls;
//     default:
//       return urls;
//   }
// }
// function cubemapUrls1x6(urls = null, action) {
//   switch (action.type) {
//     case types.SET_CUBEMAP_URLS_1X6:
//       return action.urls;
//     default:
//       return urls;
//   }
// }
// function panoramaImages(images = null, action) {
//   switch (action.type) {
//     case types.SET_PANORAMA_IMAGES:
//       return action.images;
//     default:
//       return images;
//   }
// }
const todoApp = combineReducers({
    building,
    id,
    // panoramaImages,
    // dollHouseCubemap,
    // doorModels,
    // roomModels,
    viewButton,
    viewVariable,
    // labels,
    // hierarchy,
    // buildingId,
    // cubemapUrls,
    // cubemapUrls1x6,
    currentRenderIndex,
    currentHotSpot,
    // currentRenderFloorID,
    queryStringList
});

export default todoApp;