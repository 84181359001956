import React from 'react';
// import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { STATE } from 'services/3dviewer/moving-controls/synchronizeController'
import store from 'store';
import rectangle from 'asset/image/rectangle.svg';
import { ReactComponent as Rect } from 'asset/image/rectangle.svg';
import back from 'asset/image/back.svg';
import hide from 'asset/image/hide.svg';
import FloorPlaneCanvas from '../floorplan-canvas';
import './css/MenuStyle.scss';

export default class ViewChangeMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMenu: false,
      displayBack: false,
      openMenu: true,
      hoverMenu: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.hoverMenu = this.hoverMenu.bind(this);
    this.viewChange = this.viewChange.bind(this);
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.viewChange); // add moniter changes from store and set listener
    this.viewChange();
    this.props.parent.setState({
      isNotFirst: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type != this.props.type) {
      this.forceUpdate();
    }

    if (prevProps.display != this.props.display) {
      this.forceUpdate();
    }
  }

  componentWillUnmount(){
    this.unsubscribe();
  }

  toggleMenu() {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  }

  hoverMenu() {
    this.setState({
      hoverMenu: !this.state.hoverMenu,
    });
  }

  viewChange() {
    const { viewButton, viewVariable } = store.getState();
    const { viewState } = viewButton;

    if (viewVariable.isCameraMoving) {
      this.setState({
        displayMenu: false,
        displayBack: false
      });
    }
    else {
      this.setState({
        displayMenu: viewState === STATE.FPVIEW,
        displayBack: !(viewState === STATE.FPVIEW)
      });
    }
  }

  render() {
    const { viewButton } = store.getState();
    const { goDown, goUp, toOrthographics } = viewButton;
    const { displayMenu, displayBack, openMenu, hoverMenu} = this.state;

    let rwdPostfix = (this.props.type == 'Mobile') ? '-mobile' : '';

    const menuIconStyle = (this.props.type == 'Mobile') ? 
    {
      transform: openMenu ? 'rotate(0.5turn)' : 'rotate(0turn)',
      WebkitTransform: openMenu ? 'rotate(0.5turn)' : 'rotate(0turn)'
    } : 
    {
      transform: openMenu ? 'rotate(0.25turn)' : 'rotate(-0.25turn)',
      WebkitTransform: openMenu ? 'rotate(0.25turn)' : 'rotate(-0.25turn)'
    };

    const menuOpenStyle = (this.props.type == 'Mobile') ? 
    {
      display: displayMenu ? '' : 'none',
      transform: openMenu ? 'translateX(0%)' : 'translateX(-100%)',
      WebkitTransform: openMenu ? 'translateX(0%)' : 'translateX(-100%)'
    } :
    {
      display: displayMenu ? '' : 'none',
      transform: openMenu ? 'translateY(0px)' :
                hoverMenu ? 'translateY(339px)' : 'translateY(345px)',
      WebkitTransform: openMenu ? 'translateY(0px)' :
                      hoverMenu ? 'translateY(339px)' : 'translateY(345px)'
    }

    const menuBoardStyle = (this.props.type == 'Mobile') ? 
    {
      display: displayMenu ? '' : 'none',
      transform: openMenu ? 'translateX(-100%)' : 'translateX(0%)',
      WebkitTransform: openMenu ? 'translateX(-100%)' : 'translateX(0%)'
    } :
    {
      display: displayMenu ? '' : 'none',
      transform: openMenu ? 'translateY(0px)' :
                hoverMenu ? 'translateY(345px)' : 'translateY(339px)',
      WebkitTransform: openMenu ? 'translateY(0px)' :
                      hoverMenu ? 'translateY(345px)' : 'translateY(339px)'
    }

    const containerDisplay = (this.props.display) ? 
    {
      display: ''
    } :
    {
      display: 'none'
    }
    let togglebuttonStatus = ""
    if(openMenu){
      togglebuttonStatus = " Open"
    }
    
    return (
      <div className="view-menu-container" style={containerDisplay}>
        {
          displayBack &&
          <button
            className={"fpview-button"}
            type="button"
            onClick={goDown}
          >
            <img src={back} alt="back" />
          </button>
        }
        {
          this.props.type == 'Mobile' && displayMenu  &&
          <button
            className={"menu-toggle-button-closed-mobile" + togglebuttonStatus }
            type="button"
            onClick={this.toggleMenu}
            style={menuBoardStyle}
          >
            <img src={hide} alt="hide" style={menuIconStyle}/>
            <i className="icon-hide"> </i>
            <span className="buttonText">
              <Trans>ViewModeFloorPlan</Trans>
            </span>
          </button>
        }
        <div className={"floorplan-topview-container" + rwdPostfix + togglebuttonStatus} style={menuOpenStyle}>
          <button
            className={"menu-toggle-button" + rwdPostfix}
            type="button"
            onClick={this.toggleMenu}
            onMouseEnter={this.hoverMenu}
            onMouseLeave={this.hoverMenu}
          >
            <img src={hide} alt="hide" style={menuIconStyle}/>
            <i className="icon-hide"> </i>
            <span className="buttonText">
              &nbsp;
              <Trans>ViewModeFloorPlan</Trans>
            </span>
          </button>
          <button
            className={"floorplan-button" + rwdPostfix}
            type="button"
            hidden={false}
            onClick={() => {
              toOrthographics();
            }}
          >
            <FloorPlaneCanvas open={displayMenu && openMenu} viewerCameraController={this.props.viewerCameraController} objectManager={this.props.objectManager}/>
          </button>
          <button
            className={"topview-button" + rwdPostfix}
            type="button"
            hidden={false}
            onClick={() => {
              goUp();
            }}
          >
            {/* <img src={rectangle} alt="rectangle" /> */}
            <Rect className="icon-3d-view" alt="rectangle" />
            <i className="icon-3d-view"> </i>
            <span className="buttonText">
              &nbsp;
              <Trans>ViewModeDollhouseView</Trans>
            </span>
          </button>
        </div>
      </div>
    );
  }
}
