import React, { Component } from 'react';
// import { isMobile } from 'react-device-detect';
import FloorSelectionList from './floorSelectionList';
import RoomSelectionList from './roomSelectionList';
import MultiFloorDropdown from './multiFloorDropdown';
import store from 'store';
import actions from 'store/actions';
import {
  STATE,
  SynchronizeController,
} from 'services/3dviewer/moving-controls/synchronizeController';

export default class SelectionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingData: null,
      currentRoomIndex: 0,
      hideFloor: true,
      hideRoom: true,
      hideMultiFloor: true,
      RWD: 'PC',
    };
    this.updateList = this.updateList.bind(this);
    this.closeList = this.closeList.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.viewChange = this.viewChange.bind(this);
    this.handleRWD = this.handleRWD.bind(this);
  }

  componentDidMount() {
    store.subscribe(this.viewChange); // add moniter changes from store and set listener
    this.viewChange();

    this.init();
    this.handleRWD();
    window.addEventListener('click', this.checkStatus);
    window.addEventListener('resize', this.handleRWD);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.checkStatus);
  }

  getFloorList() {
    const { building } = store.getState();
    let floorDataList = building.floorList;
    let floorList = [];

    if (this.state.buildingData !== null) {
      if (floorDataList.length > 1)
      {
        floorDataList.forEach((floor, index)=>{
          if(floor.name != undefined)
            floorList.push({ value: index, label: floor.name });
        })
      }
    }
    return floorList;
  }

  getRoomList() {
    let roomList = [];
    
    const { currentRenderIndex } = store.getState();
    if (this.state.buildingData !== null) {
      // console.log(this.state.buildingData, currentRenderIndex)
      if (currentRenderIndex != null && currentRenderIndex != -1) {
        roomList = this.state.buildingData[currentRenderIndex].styles[0];
      }
    }
    return roomList;
  }

  viewChange() {
    const { viewButton, viewVariable } = store.getState();
    if (viewButton !== null && viewVariable !== null) {
      const { viewState } = viewButton;
      if (!SynchronizeController.isMoving) {
        if (viewState === STATE.FPVIEW && this.state.hideRoom) {
          this.hideList(false);
          this.handleRWD();
        }
      }

      if (viewVariable.isCameraMoving) {
        this.setState({
          hideMultiFloor: true,
        });
      } else {
        this.setState({
          hideMultiFloor: viewState === STATE.FPVIEW,
        });
      }
    }
  }

  init() {
    const { building } = store.getState();
    let buildingData = [];
    let floorList = building.floorList;
    // console.log(building);

    floorList.forEach(floor => {
      // let floor = floorList[index];
      let hierarchy = floor.hierarchy;
      let styles = [];
      for (let j = 0; j < hierarchy.length; j++) {
        // parse room info
        let roomList = [];
        let index = 0;
        for (const [key, value] of Object.entries(hierarchy[j].panoramas)) {
          if (value.info.isShowing != false) {
            roomList.push({
              value: index++,
              id: key,
              label: value.info.type,
            });
          }
        }
        styles.push(roomList);
      }
      let label = floor.name;
      buildingData.push({ styles: styles, label: label, id: floor.id });
    });
    // console.log(buildingData);

    this.setState({
      buildingData: buildingData,
    });
  }

  checkStatus() {
    const { viewButton } = store.getState();
    const { viewState } = viewButton;
    if (viewState !== STATE.FPVIEW) {
      this.hideList(true);
      requestAnimationFrame(this.checkStatus);
    } else {
      if (this.props.parent.leftScene) {
        let currentPanoId = this.props.parent.leftScene.threeScene.getCurrentPanoId();
        if (currentPanoId === '') requestAnimationFrame(this.checkStatus);
        else {
          this.hideList(false);
        }
        this.closeList();
      }
    }
  }

  handleRWD() {
    if (window.innerWidth < 1366) {
      this.setState({
        RWD: 'Mobile',
      });
    } else {
      this.setState({
        RWD: 'PC',
      });
    }
  }

  closeList() {
    if (this.floorSelectionList !== null) 
      this.floorSelectionList.closeList();
    if (this.roomSelectionList !== null)
      this.roomSelectionList.closeList();
  }

  hideList(status) {
    this.setState({
      hideFloor: status,
      hideRoom: status,
    });

    if (status === false) this.updateList();
  }

  updateList = () => {
    let floorList = this.getFloorList();
    let roomList = this.getRoomList();
    if (this.floorSelectionList)
      this.floorSelectionList.updateList(floorList);
    if (this.roomSelectionList)
      this.roomSelectionList.updateList(roomList);
    // console.log(roomList);
  };

  changeFloor(index) {
    let { currentRenderIndex } = store.getState();
    SynchronizeController.changeFloor(index);
    this.updateList();
  }

  render() {
    let floorList = this.getFloorList();
    let roomList = this.getRoomList();

    if (this.state.RWD === 'PC') {
      return (
        <div>
          {this.state.buildingData !== null && !this.state.hideFloor && (
            <FloorSelectionList
              RWD={this.state.RWD}
              options={floorList}
              ref={ref => {
                this.floorSelectionList = ref;
              }}
              parent={this}
            />
          )}
          {this.state.buildingData !== null && !this.state.hideRoom && (
            <RoomSelectionList
              RWD={this.state.RWD}
              options={roomList}
              alignLeft={this.state.buildingData.length <= 1}
              ref={ref => {
                this.roomSelectionList = ref;
              }}
              parent={this}
              currentRoom={this.state.currentRoomIndex}
            />
          )}
          {this.state.buildingData !== null && !this.state.hideMultiFloor && (
            <MultiFloorDropdown
              RWD={this.state.RWD}
              mode={this.props.parent.mode}
              options={floorList}
              ref={ref => {
                this.multiFloorList = ref;
              }}
              parent={this}
            />
          )}
        </div>
      );
    } else if (this.state.RWD === 'Mobile') {
      return (
        <div>
          {/* <div
            className="show-dropdown-close-mobile"
            style={{
              width: this.state.buildingData.length <= 1 ? 100 : 200,
              background: this.state.hideRoom
                ? 'rgba(0, 0, 0, 0)'
                : 'rgba(255, 255, 255, 0.4)',
            }}
          > */}
            {this.state.buildingData !== null && !this.state.hideFloor && (
              <FloorSelectionList
                RWD={this.state.RWD}
                options={floorList}
                ref={ref => {
                  this.floorSelectionList = ref;
                }}
                parent={this}
              />
            )}
            {/* {this.state.buildingData.length > 1 && !this.state.hideFloor && (
              <i className="dropdown-close-mobile-frame"></i>
            )} */}
            {this.state.buildingData !== null && !this.state.hideRoom && (
              <RoomSelectionList
                RWD={this.state.RWD}
                options={roomList}
                alignLeft={this.state.buildingData.length <= 1}
                ref={ref => {
                  this.roomSelectionList = ref;
                }}
                parent={this}
                currentRoom={this.state.currentRoomIndex}
              />
            )}
          {/* </div> */}
          {this.state.buildingData !== null && !this.state.hideMultiFloor && (
            <MultiFloorDropdown
              RWD={this.state.RWD}
              mode={this.props.parent.mode}
              options={floorList}
              ref={ref => {
                this.multiFloorList = ref;
              }}
              parent={this}
            />
          )}
        </div>
      );
    }
  }
}
