import React, { Component } from 'react';
import store from 'store';
import StoreGetters from 'store/store-getters';
import { STATE } from 'services/3dviewer/moving-controls/synchronizeController';
import { VIEW } from '../config';

import './css/roomtypetag.scss';

export default class RoomTag extends Component {
  constructor() {
    super();
    this.state = {
      style: { bottom: '50%', right: '50%' },
      isLabelShow: true,
      isShow: true,
    };

    this.roomtype = '';
    this.floorIndex = -1;

    this.onClick = () => { };
  }

  componentDidMount() {
    const { room } = this.props;
    const { mainRoomId, mesh } = room;

    const { building } = store.getState();

    search: for (let index in building.floorList) {
      try {
        const { hierarchy } = building.floorList[index];
        for (let i = 0; i < hierarchy.length; i++) {
          const roomDict = hierarchy[i];
          if (roomDict.panoramas[mainRoomId]) {
            this.roomtype = roomDict.panoramas[mainRoomId].info.type;
            if (this.roomtype) {
              this.floorIndex = parseInt(index);
              break search;
            }
          }
        }
      } catch (e) {
        console.warn(e);
        continue;
      }
    }
    
    this.setVisible();
    mesh.onAfterRender = this.setPos;

    this.onClick = () => {
      const { viewButton } = store.getState();
      const { goDependOnState } = viewButton;
      goDependOnState(mainRoomId);
    };

    this.unsubscribe = store.subscribe(this.setVisible);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.mode !== this.props.mode) {
      this.setVisible();
    }
  }

  setPos = () => {
    const { room, isMain, mode, type, scene } = this.props;

    let scale = 50;
    let offset = 50;
    switch (mode) {
      case VIEW.SINGLE:
        if (isMain) {
          scale = 100;
          offset = 0;
        } else {
          // this.hide();
        }
        break;
      case VIEW.DOUBLE:
        if (isMain) {
          scale = 50;
          offset = 50;
        } else {
          scale = 50;
          offset = 0;
        }
        break;
      case VIEW.RIGHTFULL:
        if (isMain) {
          // this.hide();
        } else {
          scale = 100;
          offset = 0;
        }
        break;
    }

    const screenCoordinate = room.getTagScreenPosition(
      scene.viewerCameraController
    );

    if (
      screenCoordinate.x > 0 &&
      screenCoordinate.x < 1 &&
      screenCoordinate.y > 0 &&
      screenCoordinate.y < 1 &&
      screenCoordinate.z < 1.0 &&
      screenCoordinate.z > -1.0
    ) {
      if (this.state.isLabelShow) this.show();

      if (type === 'PC') {
        const screenX = (1 - screenCoordinate.x) * scale + offset;
        const screenY = (1 - screenCoordinate.y) * 100;
        this.updatePosition(screenX, screenY);
      } else {
        const screenX = (1 - screenCoordinate.x) * 100;
        const screenY = (1 - screenCoordinate.y) * scale + offset;
        this.updatePosition(screenX, screenY);
      }
      return;
    }

    this.hide();
  };

  setVisible = () => {
    const { viewButton, currentRenderIndex } = store.getState();
    const { viewState } = viewButton;
    const { room } = this.props;
    
    if (
      (currentRenderIndex == -1 || currentRenderIndex == this.floorIndex) &&
      viewState !== STATE.FPVIEW
    ) {
      room.tagVisible = true;
      this.labelShow(true);
      this.show();
      return;
    }

    room.tagVisible = false;
    this.labelShow(false);
    this.hide();
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  updatePosition = (left, top) => {
    this.setState({
      style: { right: `${left.toString()}%`, bottom: `${top.toString()}%` },
    });
  };

  labelShow(status) {
    this.setState({
      isLabelShow: status,
    });
  }

  show() {
    this.setState({
      isShow: true,
    });
  }

  hide() {
    this.setState({
      isShow: false,
    });
  }

  render() {
    return (
      <>
        {this.state.isShow == true && (
          <button
            className="roomTag"
            style={this.state.style}
            onClick={this.onClick}
          >
            {this.roomtype}
          </button>
        )}
      </>
    );
  }
}
