import SpriteText from 'three-spritetext';

// 修正｀three-spritetext _genCanvas 會將scale.z設置為0 導致大量warning
SpriteText.prototype._genCanvas = new Proxy(SpriteText.prototype._genCanvas, {
  apply(target, inst) {
    Reflect.apply(...arguments);
    const {x, y} = inst.scale;
    inst.scale.set(x, y, 0.01);
  }
})

export default SpriteText