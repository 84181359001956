import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import  Main from './page/main';
import './i18n';
import { getAppVersion } from './services/utils/getAppVersion'

const ViewerScene = props => {
  const { match } = props;
  const { url, length } = match;
  getAppVersion()
  console.log("Deploy time: 2021/12/22 16:30")
  const buildingId = url.substring(1, length);
  return <Main buildingId={buildingId} />;
};
ViewerScene.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/*" exact component={ViewerScene} />
    </Switch>
  </BrowserRouter>,
  document.querySelector('#root')
);

console.log(`Mode: ${process.env.REACT_APP_NODE_ENV}`);
