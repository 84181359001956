import * as THREE from 'three';

import store from 'store';
import StoreGetters from 'store/store-getters';
import {
	createviewerCameraControllers,
	viewerCameraControllers,
} from './camera/cameraControls';
import mouseEvents from './mouse-events';
import MovingController from './moving-controls';
import {
	STATE,
	SynchronizeController,
} from './moving-controls/synchronizeController';

export default class ThreeRenderScene {
	constructor(canvas, isMain) {
		this.scene = new THREE.Scene();
		this.viewerCameraController =
			viewerCameraControllers[createviewerCameraControllers()];
		this.viewerCameraController.init(canvas);
		this.movingController = new MovingController(this.scene);
		this.mouseEventController = new mouseEvents(
			this.viewerCameraController,
			this.movingController,
			canvas
		);

		this.currentSceneInfo = null;
		this.ObjectManager = null;
		this.isMain = isMain;

		this.animate = this.animate.bind(this);
		this.start = this.start.bind(this);
		this.init = this.init.bind(this);
	}

	animate() {
		// requestAnimationFrame(this.animate);
		// this.viewerCameraController.render(this.scene);
		this.viewerCameraController.renderer.setAnimationLoop(() => {
			this.viewerCameraController.render(this.scene);
		});
	}

	init(objManager, sceneInfo) {
		this.movingController.init(
			this.viewerCameraController,
			this.mouseEventController.mouseHintController
		);

		let wait = this.movingController.initFpMesh(objManager, sceneInfo);

		this.scene.add(this.mouseEventController.mouseHintController.mousePoint);
		this.scene.add(
			this.movingController.recordHintPointController.recordHintPoint
		);

		this.setObjManagerAndSceneInfo(objManager);
		this.setScenes(sceneInfo);
		this.setMouseEvent(sceneInfo);
		this.viewerCameraController.initCameraPositionByMesh(sceneInfo.allRoomMesh);

		this.rulerUnsubscribe = store.subscribe(this.updateRuler);
		this.roomTagUnsubscribe = store.subscribe(this.updateRoomTag);

		// set VR of main scene
		if (this.isMain) {
			this.viewerCameraController.setVRsetting();
		}

		return wait;
	}

	start(config = null) {
		const [waitRotate, waitMove] = this.movingController.start(config);
		this.animate();

		return [waitRotate, waitMove];
	}

	unMount() {
		this.rulerUnsubscribe();
		this.roomTagUnsubscribe();
	}

	setScenes(newSceneInfo) {
		if (this.currentSceneInfo != null) {
			this.scene.remove.apply(this.scene, this.currentSceneInfo.allRoomMesh);
			this.scene.remove.apply(
				this.scene,
				this.currentSceneInfo.doorBoundingGroup
			);
			this.scene.remove.apply(
				this.scene,
				this.currentSceneInfo.wallBoundingGroup
			);
			this.scene.remove.apply(
				this.scene,
				this.currentSceneInfo.floorplanAreaGroup
			);
			this.scene.remove.apply(this.scene, this.currentSceneInfo.rulerGroup);
		}
		this.currentSceneInfo = newSceneInfo;

		this.scene.add.apply(this.scene, this.currentSceneInfo.allRoomMesh);
		this.scene.add.apply(this.scene, this.currentSceneInfo.doorBoundingGroup);
		this.scene.add.apply(this.scene, this.currentSceneInfo.wallBoundingGroup);
		this.scene.add.apply(this.scene, this.currentSceneInfo.floorplanAreaGroup);
		this.scene.add.apply(this.scene, this.currentSceneInfo.rulerGroup);
	}

	setObjManagerAndSceneInfo(objManager) {
		this.ObjectManager = objManager;
	}

	setMouseEvent(sceneInfo) {
		this.mouseEventController.setHotspotDetect(
			sceneInfo.currentHotspotMeshArray
		);
		this.mouseEventController.setCanvasEvent(
			sceneInfo.currentHotspotMeshArray,
			sceneInfo.currentRoomMeshArray,
			[],
			sceneInfo.hotspots
		);
	}

	getCurrentPanoId() {
		let currentPanoId = '';
		const animeControl = this.movingController;

		if (animeControl.destinationHotspot) {
			currentPanoId = animeControl.destinationHotspot.mainRoomId;
		}
		return currentPanoId;
	}

	updateRuler = () => {
		const { viewButton, viewVariable } = store.getState();
		const { viewState } = viewButton;
		if (this.isMain) {
			if (
				viewState == STATE.FPVIEW &&
				!viewVariable.isCameraMoving &&
				viewVariable.isDrawingRuler
			) {
				let curRoomId = this.getCurrentPanoId();
				this.currentSceneInfo.rulerGroup.forEach(group => {
					group.children.forEach((child, index) => {
						if (child.roomId === curRoomId) {
							child.setVisible(true);
						} else {
							child.setVisible(false);
						}
					});
				});
				return;
			}
		}
		this.currentSceneInfo.rulerGroup.forEach(group => {
			group.children.forEach((child, index) => {
				child.setVisible(false);
			});
		});
	};

	updateRoomTag = () => {
		const { viewButton } = store.getState();
		const { viewState } = viewButton;
		const { rooms } = this.currentSceneInfo;
		Object.keys(rooms).forEach(roomId => {
			rooms[roomId].tagVisible = viewState !== STATE.FPVIEW;
		});
	};

	handleChangeFloor() {
		const { allRoomsMeshArray } = this.currentSceneInfo;

		let oldFpviewMesh = this.movingController.fpviewMesh.mesh;
		let wait = this.movingController.initFpMesh(
			this.ObjectManager,
			this.currentSceneInfo,
			this.movingController.destinationHotspot,
			this.movingController.lastDownViewHotspot,
			allRoomsMeshArray
		);

		wait.then(() => {
			this.scene.remove(oldFpviewMesh);
			this.scene.add(this.movingController.fpviewMesh.mesh);
		});

		return wait;
	}

	resolveChangeFloor() {
		const { mainroomHotspot } = this.currentSceneInfo;
		this.setMouseEvent(this.currentSceneInfo);

		let oldFpviewMesh = this.movingController.fpviewMesh.mesh;
		let wait = this.movingController.initFpMesh(
			this.ObjectManager,
			this.currentSceneInfo,
			null,
			mainroomHotspot
		);

		this.movingController.mouseHintController.MousePointVisible = false;
		this.ObjectManager.resetAllModelTexture();

		this.movingController.recordHintPointController.changeRecordHintPointPos(
			mainroomHotspot
		);

		this.movingController.recordHintPointController.setRecordHintPointVisible(
			true
		);

		wait.then(() => {
			this.scene.remove(oldFpviewMesh);
			this.scene.add(this.movingController.fpviewMesh.mesh);
		});

		return wait;
	}

	changeFloor(
		objManager,
		sceneInfo,
		destinationHotspot = null,
		lastDownViewHotspot = null
	) {
		this.setObjManagerAndSceneInfo(objManager);
		// this.setScenes(sceneInfo);
		this.setMouseEvent(sceneInfo);

		let oldFpviewMesh = this.movingController.fpviewMesh.mesh;
		let wait = this.movingController.initFpMesh(
			this.ObjectManager,
			this.currentSceneInfo,
			destinationHotspot,
			lastDownViewHotspot
		);

		wait.then(() => {
			this.scene.remove(oldFpviewMesh);
			this.scene.add(this.movingController.fpviewMesh.mesh);
		});

		return wait;
	}

	changeStyle(
		objManager,
		sceneInfo,
		destinationHotspot = null,
		lastDownViewHotspot = null
	) {
		this.setScenes(sceneInfo);
		this.setObjManagerAndSceneInfo(objManager);
		this.setMouseEvent(sceneInfo);

		let oldFpviewMesh = this.movingController.fpviewMesh;
		
		let wait = this.movingController.initFpMesh(
			this.ObjectManager,
			this.currentSceneInfo,
			destinationHotspot,
			lastDownViewHotspot
		);

		wait.then(() => {
			this.scene.remove(oldFpviewMesh.mesh);
			oldFpviewMesh.dispose();

			this.scene.add(this.movingController.fpviewMesh.mesh);
		});

		return wait;
	}

	canvasResize() {
		this.viewerCameraController.windowResized();
	}
}
