import * as THREE from 'three';
import SpriteText from '../SpriteText';
import store from 'store';

import {
    STATE,
  } from 'services/3dviewer/moving-controls/synchronizeController';

function Hotspot(iMainRoomId, iHotspotId, text) {
  const manhattanRotation = 0;
  const texture = null;
  const cameraHeight = null;
  const rotateY = null;
  const hotspotId = iHotspotId;
  const mainRoomId = iMainRoomId;
  const geometry = new THREE.CircleGeometry(0.01, 32);
  const material = new THREE.MeshBasicMaterial({
    map: null,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.4,
    depthTest: true,
  });
  const mesh = new THREE.Mesh(geometry, material);

  const spriteText = new SpriteText(text || ' ', 0.01);
  spriteText.position.z = -0.01;
  spriteText.visible = false;
  mesh.add(spriteText);
  spriteText.padding = 5;
  spriteText.scale.set(0.04,0.02,0.02)

  const onClickColor = null;
  let mouseIsEnter = null;

  function update() {
    const { viewButton, viewVariable } = store.getState();
    if (viewButton) {
      const { viewState } = viewButton;

      if (viewState == STATE.FPVIEW) {
        spriteText.visible = true;
      } else if(viewState == STATE.FLOORPLAN){
        spriteText.visible = false;
      } else{
        spriteText.visible = false;
      }
    }
  }
  function destroy() {
    geometry.dispose();
    material.dispose();
    if (texture) texture.dispose();
  }
  function onMouseEnter() {
    if (mouseIsEnter === false || mouseIsEnter == null) {
      mouseIsEnter = true;
      material.opacity = 1;
    }
  }
  function onMouseLeave() {
    if (mouseIsEnter === true) {
      mouseIsEnter = false;
      material.opacity = 0.4;
    }
  }
  function onClick() {
    material.color.set(onClickColor);
  }
  function normalizeScale(factor) {
    const newScale = mesh.scale.x * factor;
    mesh.scale.set(newScale, newScale, newScale);
  }
  function setManhattanRotation(iManhattanRotation) {
    this.manhattanRotation = iManhattanRotation;
  }
  function setTexture(iTexture) {
    material.map = iTexture;
    mesh.material = material;
  }
  function setCameraHeight(height) {
    this.cameraHeight = height;
  }
  function setRotateY(iRotateY) {
    this.rotateY = iRotateY;
  }
  function setOpacity(opacity) {
    material.opacity = opacity;
  }
  this.setTexture = setTexture;
  this.setRotateY = setRotateY;
  this.manhattanRotation = manhattanRotation;
  this.normalizeScale = normalizeScale;
  this.onClick = onClick;
  this.rotateY = rotateY;
  this.setCameraHeight = setCameraHeight;
  this.cameraHeight = cameraHeight;
  this.onMouseEnter = onMouseEnter;
  this.onMouseLeave = onMouseLeave;
  this.destroy = destroy;
  this.mainRoomId = mainRoomId;
  this.hotspotId = hotspotId;
  this.setOpacity = setOpacity;
  this.setManhattanRotation = setManhattanRotation;
  this.update = update
  
  this.updateUnsubscribe = store.subscribe(this.update);
  Object.defineProperty(this, 'rotateY', {
    value: this.rotateY,
    enumerable: true,
  });
  Object.defineProperty(this, 'mesh', {
    value: mesh,
    enumerable: true,
  });
  Object.defineProperty(this, 'cameraHeight', {
    value: this.cameraHeight,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'mainRoomId', {
    value: this.mainRoomId,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'hotspotId', {
    value: this.hotspotId,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'objectType', {
    value: 'hotspot',
    enumerable: true,
  });
  Object.defineProperty(mesh, 'onMouseLeave', {
    value: this.onMouseLeave,
    enumerable: true,
  });
  Object.defineProperty(mesh, 'onMouseEnter', {
    value: this.onMouseEnter,
    enumerable: true,
  });
}
export default Hotspot;
